export const infoUser = {
  step: 1,
  idUts:"",
  idUsuarioWeb:"",
  nombre: "",
  apellidos: "",
  telefono: "",
  genero: "",
  via: "",
  calle: "",
  numeroCalle: "",
  escalera: "",
  piso: "",
  puerta: "",
  CP: "",
  localidad: "",
  DNI: "",
  email: "",
};
