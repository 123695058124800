import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import { Button } from "@material-ui/core";
import { AuthContext } from "../UserContext";

import ColoredLinearProgess from "./ColoredLinearProgess";
import LocationForm from "./LocationForm/LocationForm";
import UserForm from "./UserForm/UserForm";
import AccessForm from "./AccessForm/AccessForm";
import axios from "axios";
import "./Wizard.css";
import { SettingsOverscanOutlined } from "@mui/icons-material";
const Wizard = () => {
  //export default class Wizard extends Component {
  const [userState, setUserState] = useState(true);
  const [submit, setSubmit] = useState();
  const [submit2, setSubmit2] = useState();
  const [submit3, setSubmit3] = useState();
  const [next1, setNext1] = useState(false);
  const [next2, setNext2] = useState();
  const [next3, setNext3] = useState();

  const [valid, setValid] = useState();

  const {
    userInfo,
    setUserInfo,
    storageUser,
    citaInfo,
    setCitaInfo,
    storageCita,
  } = useContext(AuthContext);
  const [optionsUnique, setOptionsUnique] = useState({});
  const [streets, setStreets] = useState([]);

  const checkIfExist = function (fatherList, categoryKey, element) {
    let list = fatherList[categoryKey];
    if (list == []) {
      return false;
    } else {
      for (let key in list) {
        if (element == list[key].label) {
          return true;
        }
      }
    }
    return false;
  };
  // const getFilteredDistinctOptions = function(optionsToFilter){
  //   console.log("Options to filter", optionsToFilter)
  //   setOptionsUnique({})
  //   for(let filterKey in optionsToFilter[0]){
  //     optionsUnique[filterKey]=[]
  //     setOptionsUnique(optionsUnique)
  //   }
  //   for(let opt in optionsToFilter){
  //     /*centroNombre: "MURCIA SUR"
  //       ​​​​cp: 30157
  //       ​​​​idCallejero: 1
  //       ​​​​inf: "1"
  //       ​​​​nombreVia: "ALBERCA"
  //       ​​​​sup: "31"
  //       ​​​​tipoVia: "AVDA"
  //       ​utsNombre: "ALGEZARES" */
  //     for(const filterKey in optionsToFilter[opt]){
  //       let key = optionsToFilter[opt][filterKey]
  //       if(opt in [1, 2]){
  //         console.log("Comprobando ", opt, filterKey)
  //         console.log("opt", optionsToFilter[opt])
  //         console.log("optionsUnique", optionsUnique)
  //         console.log("A comprobar",key, "\n")
  //         console.log("filterKey", filterKey)

  //         console.log("Includes", !["idCallejero", "cp", "inf", "sup"].includes(filterKey));
  //         console.log("Some", !checkIfExist(optionsUnique, filterKey, key))
  //         console.log("\n")
  //       }
  //       if(!["idCallejero", "cp", "inf", "sup"].includes(filterKey) && !checkIfExist(optionsUnique, filterKey, key)){

  //         // if(opt in [1, 2, 3]){
  //         //     console.log("Añadiendo key",key)

  //         // }
  //         // optionsUnique[key] = key
  //         let element={label:key}
  //         setOptionsUnique([optionsUnique[filterKey].push(element)])
  //       }

  //     }//for filterKey
  //   }//for opt
  //   console.log("Resultado, opciones:",optionsUnique )
  // }
  const getFilteredDistinctOptions = function (optionsToFilter) {
    setOptionsUnique({});
    for (let filterKey in optionsToFilter[0]) {
      optionsUnique[filterKey] = [
        ...new Set(optionsToFilter.map((item) => item[filterKey])),
      ];
      setOptionsUnique(optionsUnique);
      // const unique = [...new Set(optionsToFilter.map(item => item[filterKey]))]; // [ 'A', 'B']
    }
    // optionsUnique.set("empty", []);
    // setOptionsUnique({ ...optionsUnique, empty: [] });
    // console.log("Options Unique", optionsUnique);
  };
  function getCallejero() {
    //console.log("Obteniendo CALLEJERO: ");
    if (streets.length == 0) {
      axios
        .get(process.env.REACT_APP_URL_API + "/Callejero/all", {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((res) => {
          //console.log(res)
          //console.log(res.body)
          // console.log("Calles", res.data);
          setStreets(res.data);
          getFilteredDistinctOptions(res.data);
          //  window.location.href = "/inicio";
        })
        .catch((err) => {});
    }
  }
  useEffect(() => {
    if (userInfo.DNI === "") {
      const info = storageUser;
      //console.log("Recargando datos", info);
      setUserInfo(info);
    }
  }, []);

  useEffect(() => {
    // console.log("next1");
    if (streets.length == 0) {
      // console.log("Cargando callejero");
      getCallejero();
    }
    if (next1) {
      nextStep();
    }
  }, [next1]);

  useEffect(() => {
    if (next2) {
      nextStep();
    }
  }, [next2]);
  let step = 1;
  const [wizardStep, setWizardStep] = useState(step);
  const prevStep = () => {
    // const { step } = userInfo;
    setWizardStep(wizardStep - 1);
  };
  // proceed to the next step
  const nextStep = () => {
    // const { step } = wizardStep;

    setWizardStep(wizardStep + 1);
  };
  function crearUsuario() {
    // setUserInfo(storageUser);
    // setUserInfo({
    //   ...userInfo,
    //   escalera: storageUser.escalera,
    //   piso: storageUser.piso,
    //   puerta: storageUser.puerta,
    //   CP: storageUser.CP,
    //   localidad: storageUser.localidad,
    // });
    // setUserInfo(userInfo);
    // console.log("ESTAMOS: ", userInfo);
    axios
      .post(process.env.REACT_APP_URL_API + "/UsuarioWeb/create", {
        dni: userInfo.DNI,
        telefono: userInfo.telefono,
        nombre: userInfo.nombre,
        apellidos: userInfo.apellidos,
        email: userInfo.email,
        localizacionNombre: userInfo.calle,
        localizacionTipo: userInfo.via,
        localizacionCp: storageUser.CP,
        localizacionVia: userInfo.via,
        localizacionNumero: userInfo.numeroCalle,
        localizacionEscalera: storageUser.escalera,
        localizacionPiso: storageUser.piso,
        localizacionPuerta: storageUser.puerta,
        localizacionLocalidad: storageUser.localidad,
        sexo: userInfo.genero,
        idUts: userInfo.idUts,
      })
      .then((res) => {
        // console.log(res);
        // console.log(res.body);
        // console.log(res.data);
        userInfo.idUsuarioWeb = res.data.idUsuarioWeb;
        userInfo.persistData = true;
        setUserInfo(userInfo);
        localStorage.setItem("SSMURCIA-USER-INFO", JSON.stringify(userInfo));
        window.location.href = "/inicio";
      })
      .catch((err) => {
        // console.log("Error", err);
      });
  }

  const Continue = (e) => {
    if (wizardStep === 1) {
      setSubmit(!submit);
      // console.log("ESTÁ ENTRANDO EN WIZARD STEP 1?", submit, wizardStep);
      if (
        userInfo.nombre !== "" &&
        userInfo.apellidos !== "" &&
        userInfo.sexo !== ""
      ) {
        e.preventDefault();
        //        nextStep();
        if (next1) {
          nextStep();
        }
      }
    }
    if (wizardStep === 2) {
      setSubmit2(!submit2);
      // console.log("ESTÁ ENTRANDO EN WIZARD STEP 2?", submit2, wizardStep);
      // console.log("UserInfo wizardStep2", userInfo);
      if (
        userInfo.via !== "" &&
        userInfo.calle !== "" &&
        userInfo.CP !== "" &&
        userInfo.localidad !== ""
      ) {
        e.preventDefault();
        if (next2) {
          nextStep();
        }
      }
    }
    if (wizardStep === 3) {
      console.log("UserInfo wizardStep3", userInfo);
    }
  };
  const Previous = (e) => {
    setUserState(!userState);
    e.preventDefault();
    prevStep();
  };
  const handleChange = (input) => (e) => {
    setWizardStep({ [input]: e.target.value });
  };

  const {
    nombre,
    apellidos,
    telefono,
    genero,
    via,
    calle,
    numeroCalle,
    escalera,
    piso,
    puerta,
    CP,
    localidad,
    DNI,
    email,
  } = userInfo;
  const values = {
    nombre,
    apellidos,
    telefono,
    genero,
    via,
    calle,
    numeroCalle,
    escalera,
    piso,
    puerta,
    CP,
    localidad,
    DNI,
    email,
  };
  let header = (
    <div>
      <div className="wizard-header-title-box">
        <label className="text-title">Asistente de registro</label>
      </div>
      <div className="wizard-header-desc-box">
        <label>
          Parece que aún no has usado el servicio por internet. Por favor,
          completa los siguientes datos.
        </label>
      </div>
      <div className="wizard-header-buttons">
        <button
          className={
            wizardStep === 1 ? "selected-tab-button" : "non-selected-tab-button"
          }
        >
          Usuario
        </button>
        <button
          className={
            wizardStep === 2 ? "selected-tab-button" : "non-selected-tab-button"
          }
        >
          Dirección
        </button>
        <button
          className={
            wizardStep === 3 ? "selected-tab-button" : "non-selected-tab-button"
          }
        >
          Acceso
        </button>
      </div>
      <div className="wizard-header-progress-bar">
        <ColoredLinearProgess
          className="progressBar"
          variant="determinate"
          value={wizardStep * 33.3}
        />
      </div>
    </div>
  );
  let form;
  switch (wizardStep) {
    case 1:
      form = (
        <div>
          <UserForm
            submit={submit}
            context={userInfo}
            setContext={setUserInfo}
            setNext={setNext1}
          />
        </div>
      );
      break;

    case 2:
      form = (
        <LocationForm
          submit={submit2}
          setSubmit={setSubmit2}
          context={userInfo}
          setContext={setUserInfo}
          setNext={setNext2}
          filterList={optionsUnique}
          setFilterList={setOptionsUnique}
          streets={streets}
        />
      );
      break;

    case 3:
      form = (
        <AccessForm
          submit={submit3}
          context={userInfo}
          setContext={setUserInfo}
          setNext={setNext3}
        />
      );
      break;
    // never forget the default case, otherwise VS code would be mad!
    default:

    // do nothing
  }
  let bottomButtons = (
    <div className="bottomButton-box">
      <button
        className={
          wizardStep === 1
            ? "buttomButtons-left-disabled"
            : "bottomButtons-left"
        }
        disabled={wizardStep === 1 ? true : ""}
        onClick={Previous}
      >
        Anterior
      </button>
      <button
        className="bottomButtons-right"
        onClick={Continue}
        hidden={wizardStep === 3 ? true : ""}
        // style={{ display: wizardStep === 3 ? "flex" : "none" }}
      >
        Siguiente
      </button>
      {/* {console.log("ESTAMOS?15", userInfo)} */}
      <button
        className="bottomButtons-right"
        hidden={wizardStep === 3 ? "" : true}
        // style={{ display: wizardStep === 3 ? "flex" : "none" }}
        onClick={(e) => {
          console.log("Estamos en el boton");
          setSubmit3(!submit3);
          if (userInfo.DNI !== "") {
            console.log("ESTAMOS? 2", storageUser);
            e.preventDefault();
            localStorage.setItem(
              "SSMURCIA-USER-INFO",
              JSON.stringify(storageUser)
            );

            crearUsuario();
            //window.location.href = "/inicio";
          }
        }}
      >
        Enviar
      </button>
    </div>
  );
  return (
    <div className="wizard-box">
      <div>{header}</div>
      <div className="wizard-form-1">{form}</div>
      <div className="wizard-buttons-row-1">{bottomButtons}</div>
    </div>
  );
};

export default Wizard;
