import React from "react";
import { useState, useEffect } from "react";
import "./Input_7.css";
import InfoIcon from "@mui/icons-material/Info";
import { Acordion } from "../../Acordion/Acordion";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { BorderColor } from "@mui/icons-material";

export const Input_7 = ({
  value,
  title,
  infoTitle,
  infoText,
  inputPlaceHolder,
  inputTitle,
  inputAlt,
  inputName,
  mandatory,
  submit,
  context,
  setContext,
  contextName,
  regex,
  hasRegex,
  regexText,
  finish,
  setFinish,
  finishName,
  filterList,
  filterKey,
  hasChange,
  setHasChange,
  disabled,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isMandatory, setIsMandatory] = useState(true);
  const [isRegex, setIsRegex] = useState(true);
  const [values, setValues] = useState();
  const [optionsUnique, setOptionsUnique] = useState([]);

  useEffect(() => {
    // console.log("VIA filterList?", filterList[filterKey]);
    // console.log("Que tenemos filterKeys?", filterKey)
    if (filterKey !== null) {
      setOptionsUnique(filterList[filterKey]);
    } else {
      setOptionsUnique([]);
    }
    setValues(context[contextName]);
    // hasChange = false;
  }, [context]);

  useEffect(() => {
    // console.log("HAS CHANGE ESTÁ SALTANDOO!!");
    if (filterKey !== null) {
      setOptionsUnique(filterList[filterKey]);
    } else {
      setOptionsUnique([]);
    }
    setValues(context[contextName]);
    // hasChange = false;
  }, [hasChange]);

  useEffect(() => {
    setValues(context[contextName]);
    //console.log("input-button")
    if (submit != undefined) {
      if (
        mandatory &&
        (context[contextName] === undefined ||
          context[contextName] === null ||
          context[contextName] === "")
      ) {
        setIsMandatory(false);
        if (setFinish !== undefined) {
          if (finishName != undefined) {
            finish[finishName] = false;
            setFinish(finish);
          } else {
            setFinish(false);
          }
        }
        if (setHasChange !== undefined) {
          setHasChange(!hasChange);
          // } else {
          //   setHasChange(false);
        }
        return;
      }
      if (hasRegex) {
        const regexF = new RegExp(regex);
        if (!regexF.test(values)) {
          // console.log("sadfasdfsdaffsdaafsdfd");
          setIsRegex(false);
        } else {
          setIsRegex(true);
          if (setFinish !== undefined) {
            if (finishName != undefined) {
              finish[finishName] = true;
              setFinish(finish);
            } else {
              setFinish(true);
            }
          }
          if (setHasChange !== undefined) {
            setHasChange(!hasChange);
            // } else {
            //   setHasChange(false);
          }
        }
      } else {
        if (setFinish !== undefined) {
          if (finishName != undefined) {
            finish[finishName] = true;
            setFinish(finish);
          } else {
            setFinish(true);
          }
        }
        if (setHasChange !== undefined) {
          setHasChange(!hasChange);
          // } else {
          //   setHasChange(false);
        }
      }
    }
  }, [submit]);

  function handleChange(e, value) {
    // console.log("e ", e);
    // console.log("v ", value);
    // console.log("context: ", context);
    context[contextName] = value;
    setContext(context);
    setValues(value);
    if (setFinish !== undefined) {
      if (finishName != undefined) {
        finish[finishName] = true;
        setFinish(finish);
      } else {
        setFinish(true);
      }
    }
    if (setHasChange !== undefined) {
      // console.log("UNDEFINED", hasChange);
      setHasChange(!hasChange);
    } else {
      // console.log("FALSE");
      setHasChange(false);
    }
    if (
      context[contextName] !== null &&
      context[contextName] !== undefined &&
      context[contextName] !== ""
    ) {
      setIsMandatory(true);
    }
  }

  return (
    <div className="input-7-form-box">
      <div className="input-7-column-1">
        <div className="input-7-row-1">
          <label className="input-7-label-1">{title}</label>
          <div
            onClick={() => setIsDisabled(!isDisabled)}
            title="Ayuda Número de documento"
          >
            <InfoIcon
              sx={{
                color: "#c23023",
              }}
            />
          </div>
        </div>
        <Autocomplete
          //className="input-7-box"
          disabled={disabled}
          noOptionsText="No hay sugerencias"
          value={context[contextName]}
          disablePortal
          id="combo-box-demo"
          size="small"
          autoHighlight={true}
          // options={optionsUnique ? optionsUnique.map((option) => option) : ""}
          options={optionsUnique ? optionsUnique.map((option) => option) : ""}
          renderInput={(params) => <TextField {...params} label="" />}
          onChange={(e, value) => {
            handleChange(e, value);
          }}
        />

        <div
          className={
            isMandatory
              ? "input-7-mandatory-disabled"
              : "input-7-mandatory-text"
          }
        >
          Este campo es obligatorio
        </div>
        <div
          className={isRegex ? "input-7-regex-disabled" : "input-7-regex-text"}
        >
          {regexText}
        </div>
        <div className="input-7-acordeon">
          <Acordion isDisabled={isDisabled} title={infoTitle} text={infoText} />
        </div>
      </div>
    </div>
  );
};
