import { Grid, Typography } from "@mui/material";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import "./Schema.css";

import { AuthProvider } from "./UserContext";
import Navigation from "./Navigation";

const Schema = () => {
  return (
    <div className="schema-container">
      <div className="schema-header">
        <Header />
      </div>

      <div className="schema-body">
        <AuthProvider>
          <Navigation />
        </AuthProvider>
      </div>

      <div className="schema-footer">
        <Footer />
      </div>
    </div>
  );
};
export default Schema;
