import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import "./ConfirmarCita.css";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import { AuthContext } from "../../../schema/UserContext";
import axios from "axios";
//import BasicDocument from "./getPDF" Creación de PDF desde el front
//import ReactPDF, { PDFDownloadLink } from '@react-pdf/renderer'
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { MapObject } from "../MapObject";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const ConfirmarCita = () => {
  let nombreCentro = "";
  let nombreDeLaCalle = "";
  const sendEmailButtonText_notSend = "Enviar por e-mail";
  const sendEmailButtonText_send = "E-mail ya enviado";
  const [regexOk, setRegexOk] = useState(true);

  const [oldEmail, setOldEmail] = useState("");
  const [textoMarker, setTextoMarker] = useState("UTS");
  const [sendEmailText, setSendEmailText] = useState(
    sendEmailButtonText_notSend
  );
  const [emailButtonDisabled, setEmailButtonDisabled] = useState(false);
  const [emailProblem, setEmailProblem] = useState(false);
  const [map, setMap] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const {
    userInfo,
    setUserInfo,
    storageUser,
    citaInfo,
    setCitaInfo,
    storageCita,
  } = useContext(AuthContext);
  const [coordinates, setCoordinates] = useState([]);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setEmailProblem(false);
    setRegexOk(true);
    setIsOpen(false);
  }
  function MyComponent() {
    const map = useMap();
    console.log("map center:", map.getCenter());
    return null;
  }

  useEffect(() => {
    if (sendEmailText == sendEmailButtonText_notSend) {
      //setEmailButtonDisabled(true);
      setEmailButtonDisabled(false);
    } else {
      //setEmailButtonDisabled(true);
      setEmailButtonDisabled(false);
    }
  }, [sendEmailText]);
  useEffect(() => {
    const storageUser = JSON.parse(localStorage.getItem("SSMURCIA-USER-INFO"));
    const storageCita = JSON.parse(localStorage.getItem("SSMURCIA-CITA-INFO"));
    // Seteamos la fecha
    // storageCita.agenda_fecha
    function cambiarFecha(fecha) {
      let formatedDateText = "";

      let divisionFecha = fecha.split(",");
      let divisionMes = divisionFecha[0].split(" ");

      formatedDateText =
        divisionMes[1] + " " + divisionMes[0] + "." + divisionFecha[1];
      return formatedDateText;
    }

    function cambiarHora(hora) {
      let formatedTime;
      if (hora.includes("a.", "p.")) {
        let posPoints = hora.lastIndexOf(":");
        let sustituir = hora.substring(posPoints, hora.length);
        formatedTime = hora.replace(sustituir, " Horas");
      } else {
        formatedTime = hora;
      }
      return formatedTime;
    }
    let fecha = cambiarFecha(storageCita.agenda_fecha);
    let hora = cambiarHora(storageCita.agenda_hora);

    storageCita.agenda_fecha = fecha;
    storageCita.agenda_hora = hora;
    setUserInfo(storageUser);
    setCitaInfo(storageCita);
    setOldEmail(storageUser.email);
    console.log("storageUser", storageUser);
    console.log("storageCita", storageCita);
    let coord = [
      parseFloat(storageCita.uts_lat) / 10,
      parseFloat(storageCita.uts_lon) / 10,
    ];
    setCoordinates(coord);
    setTextoMarker(
      <div>
        <label>
          El centro <strong>{citaInfo.uts_name}</strong> está en :<br />{" "}
          <strong>
            <a
              target="_blank"
              href={
                "https://www.openstreetmap.org/?mlat=" +
                coord[0] +
                "&mlon=" +
                coord[1]
              }
            >
              {storageCita.uts_addr}
            </a>
          </strong>
        </label>
      </div>
    );
    setCoordinates(coord);
  }, []);
  return (
    <div className="confirmarCita-form">
      <div className="confirmarCita-row-1">
        <div className="confirmarCita-icon">
          <BookmarkIcon
            sx={{
              fontSize: 35,
            }}
          />
        </div>
        <div className="confirmarCita-row-1">
          <div className="confirmarCita-text-1">Servicio de Cita Previa</div>
          <div className="confirmarCita-text-2">Confirmar Cita</div>
        </div>
      </div>
      <div className="confirmarCita-row-2">
        <div className="confirmarCita-row-2-header-box">
          <div>
            <i className="fa fa-info"></i>

            <label className="infoBef confirmarCita-row-2-header-text">
              Resumen
            </label>
          </div>
          <div class="col-md-6">
            <p>
              <i className="fa fa-user"></i>
              <b className="pedirCita-row-2-info">
                {userInfo.nombre + " " + userInfo.apellidos}
              </b>
            </p>
            <p>
              <i className="fa fa-calendar"></i>
              <b className="pedirCita-row-2-info">
                {citaInfo.agenda_fecha + " a las " + citaInfo.agenda_hora}
              </b>
            </p>
            <p>
              <i className="fa fa-hospital-o"></i>
              <b className="pedirCita-row-2-info">
                Unidad de Trabajo Social(UTS):
              </b>{" "}
              {citaInfo.uts_name}
            </p>
            {/* <p hidden={citaInfo.uts_telefono?"":true}>
          <i className="fa fa-phone"></i>
          <b className="pedirCita-row-2-info">Teléfono de la UTS: </b> {citaInfo.uts_telefono}
        </p>
        <p hidden={citaInfo.uts_telefono2 ?"":true}>
          <i className="fa fa-phone"></i>
          <b className="pedirCita-row-2-info">Teléfono de la UTS: </b> {citaInfo.uts_telefono2}
        </p> */}
            <p hidden={citaInfo.uts_fax ? "" : true}>
              <i className="fa fa-phone"></i>
              <b className="pedirCita-row-2-info">FAX de la UTS: </b>{" "}
              {citaInfo.uts_fax}
            </p>
            <p>
              <i className="fa fa-book"></i>
              <b className="pedirCita-row-2-info">Dirección de la UTS: </b>{" "}
              {citaInfo.uts_addr}
            </p>
            <p>
              <i className="fa fa-book"></i>
              <b className="pedirCita-row-2-info"></b>La cita es{" "}
              {citaInfo.isPresencial == true ? "presencial" : "telefónica"}
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <p style={{ paddingLeft: "3rem" }}>
            <i className="fa fa-map-marker"></i>
            <b className="confirmarCita-col-2-info">
              Dirección en Google Maps:
            </b>
          </p>

          <div className="map-div">
            {coordinates.length > 0 &&
            coordinates[0] != "" &&
            textoMarker != "UTS" &&
            !modalIsOpen ? (
              <div className="map-box">
                <MapObject
                  coordinates={coordinates}
                  zoom={17}
                  textoMarker={textoMarker}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="confirmarCita-row-3">
        <div className="confirmarCita-button-1">
          <Button
            variant="outlined"
            startIcon={<EmailIcon />}
            disabled={emailButtonDisabled}
            sx={{
              color: "#fff",
              backgroundColor: "#c23023",
              borderColor: "#c23023",
              padding: "0.9rem 6rem",
            }}
            onClick={(e) => {
              e.preventDefault();

              openModal(e);
              //let url = "serviciosocial/api/v1.0/Agenda/confirm/{idAgenda}"
              //let storageCita = JSON.parse(localStorage.getItem("SSMURCIA-CITA-INFO"));
            }}
          >
            {sendEmailText}
          </Button>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <h2>Enviar correo</h2>
            {/* <button onClick={closeModal}>close</button> */}
            <div>El correo se enviará a la dirección:</div>
            <div>
              <input
                className="confirmarCita-input-box"
                type="text"
                defaultValue={userInfo.email}
                onChange={(e) => {
                  userInfo.email = e.target.value;
                  console.log("Guardando nuevo email ", userInfo.email);
                  setUserInfo({ ...userInfo, email: e.target.value });
                }}
              ></input>
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#c23023",
                  borderColor: "#c23023",
                  padding: "0.4rem 0.25rem",
                  "&:hover": {
                    backgroundColor: "#e53023",
                  },
                }}
                onClick={() => {
                  console.log("Email a usar : ", userInfo.email);
                  let regex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
                  const regexF = new RegExp(regex);
                  if (regexF.test(userInfo.email)) {
                    setRegexOk(true);

                    console.log(
                      process.env.REACT_APP_URL_API + "/Agenda/confirm",
                      storageCita.cita_id
                    );
                    console.log(citaInfo);
                    console.log(storageCita);
                    //axios.get(process.env.REACT_APP_URL_API + '/Agenda/confirm/' + storageCita.cita_id, {
                    //}).then((res) => {
                    axios
                      .post(process.env.REACT_APP_URL_API + "/Agenda/confirm", {
                        idAgenda: storageCita.cita_id,
                        email: userInfo.email,
                      })
                      .then((res) => {
                        console.log("Correo enviado con éxito");

                        setSendEmailText(sendEmailButtonText_send);
                        setEmailProblem(false);
                        localStorage.setItem(
                          "SSMURCIA-USER-INFO",
                          JSON.stringify(userInfo)
                        );
                        closeModal();
                      })
                      .catch((err) => {
                        console.log("Correo enviado");
                        setSendEmailText(sendEmailButtonText_notSend);
                        setEmailProblem(true);
                      });
                  } else {
                    setRegexOk(false);
                  }
                }}
              >
                Enviar E-Mail
              </Button>
            </div>
            <div>
              {emailProblem ? "Ha ocurrido un problema enviando el email" : ""}
              {!regexOk ? "Formato de E-Mail incorrecto" : ""}
            </div>
          </Modal>
        </div>

        <div className="confirmarCita-button-1">
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            sx={{
              color: "#fff",
              backgroundColor: "#c23023",
              borderColor: "#c23023",
              padding: "0.9rem 6rem",
            }}
            onClick={(e) => {
              e.preventDefault();
              console.log("Me voy a descargar esto");
              let url =
                process.env.REACT_APP_URL_API +
                "/Agenda/pdfCita/" +
                storageCita.cita_id;
              axios
                .get(url, {})
                .then((res) => {
                  let responseURL = res.data.url;
                  console.log("responseURL", responseURL);
                  window.open(responseURL, "_blank", "noopener,noreferrer");
                })
                .catch((err) => {
                  console.log("Error en el PDF", err);
                });

              // return <PDFDownloadLink document={<BasicDocument date_text={"17 de Febrero de 1994 a las 13:39"} UTS_name={"mi UTS"} UTS_addr={"Calle anonymo 123"} UTS_phone={"666666666"}/>} fileName="somename.pdf">
              //   {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
              // </PDFDownloadLink>
              //console.log(renderURL)
              //ReactPDF.render(<BasicDocument date_text={"17 de Febrero de 1994 a las 13:39"} UTS_name={"mi UTS"} UTS_addr={"Calle anonymo 123"} UTS_phone={"666666666"}/>, `${__dirname}/example.pdf`);
            }}
          >
            Imprimir recordatorio
          </Button>
        </div>
        <div className="confirmarCita-button-1">
          <Button
            variant="outlined"
            startIcon={<CalendarMonthIcon />}
            sx={{
              color: "#fff",
              backgroundColor: "#c23023",
              borderColor: "#c23023",
              padding: "0.9rem 6rem",
            }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/Inicio";
            }}
          >
            Pantalla de inicio
          </Button>
        </div>
      </div>
    </div>
  );
};
