import React, { useContext, useEffect, useState } from "react";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import axios from "axios";
import { AuthContext } from "../../../../schema/UserContext";

import "./CambiarHora.css";
const list = [];
export const CambiarHora = () => {
  const [hours, setHours] = useState(list);
  const {
    userInfo,
    setUserInfo,
    storageUser,
    citaInfo,
    setCitaInfo,
    storageCita,
  } = useContext(AuthContext);
  let distinctHours = {};
  useEffect(() => {
    const storageUser = JSON.parse(localStorage.getItem("SSMURCIA-USER-INFO"));
    const storageCita = JSON.parse(localStorage.getItem("SSMURCIA-CITA-INFO"));
    setUserInfo(storageUser);
    setCitaInfo(storageCita);
    console.log("storageUser", storageUser);
    console.log("storageCita", storageCita);
    axios
      .get(
        process.env.REACT_APP_URL_API +
          "/Agenda/loadAllLibre/" +
          storageUser.idUtsAsignada,
        {}
      )
      .then((res) => {
        console.log("Respuesta", res);
        setHours(res.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="cambiarHora-form">
      <div className="cambiarHora-row-1">
        <div className="cambiarHora-icon">
          <BookmarkIcon
            sx={{
              fontSize: 35,
            }}
          />
        </div>
        <div className="cambiarHora-row-1">
          <div className="cambiarHora-text-1">Servicio de Cita Previa</div>
          <div className="cambiarHora-text-2">Cambiar Cita - Escoger Hora</div>
        </div>
      </div>
      <ul className="button-list-box">
        {hours.map((item) => {
          if (
            item.fecha == citaInfo.agenda_fecha &&
            distinctHours[item.hora] != true
          ) {
            console.log(item);

            distinctHours[item.hora] = true;

            if (item.hora.search(" Horas") == -1) {
              let posPoints = item.hora.lastIndexOf(":");
              let sustituir = item.hora.substring(posPoints, item.hora.length);
              let formatedTime = item.hora.replace(sustituir, " Horas");
              console.log("pospoint", posPoints);
              console.log("formated", formatedTime);
              item.hora = formatedTime;
            }
            return (
              <li>
                <button
                  className="button"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("INFORMACIÓN DEL BOTON:", item);
                    let idUtsSeleccionada = item.idUts;
                    let idUtsAsignada = citaInfo.idUtsAsignada;
                    let idUtsAlternativa;
                    if (idUtsAsignada === idUtsSeleccionada) {
                      idUtsAlternativa = idUtsSeleccionada;
                    } else {
                      idUtsAlternativa = idUtsAsignada;
                    }
                    setCitaInfo({
                      ...citaInfo,
                      idAgenda: item.idAgenda,
                      agenda_fecha: item.fecha,
                      agenda_hora: item.hora,
                      idUtsAsignada: idUtsAsignada,
                      idUtsAlternativa: idUtsAlternativa,
                      idUtsSeleccionada: idUtsSeleccionada,
                    });
                    localStorage.setItem(
                      "SSMURCIA-CITA-INFO",
                      JSON.stringify({
                        ...citaInfo,
                        idAgenda: item.idAgenda,
                        agenda_fecha: item.fecha,
                        agenda_hora: item.hora,
                        uts_id: idUtsSeleccionada,
                        // idUtsAsignada: idUtsAsignada,
                        // idUtsAlternativa: idUtsAlternativa,
                        idUtsSeleccionada: idUtsSeleccionada,
                      })
                    );
                    localStorage.setItem(
                      "SSMURCIA-USER-INFO",
                      JSON.stringify({
                        ...userInfo,
                        // idAgenda: item.idAgenda,
                        // agenda_fecha: item.fecha,
                        // agenda_hora: item.hora,
                        idUts: idUtsSeleccionada,
                        // idUtsAsignada: idUtsAsignada,
                        // idUtsAlternativa: idUtsAlternativa,
                        idUtsSeleccionada: idUtsSeleccionada,
                        // idUtsAsignadaHasToUpdate: false,
                      })
                    );
                    window.location.href = "/PedirCita";
                  }}
                >
                  <i class="fa fa-fw fa-calendar" />
                  {item.hora}
                </button>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};
