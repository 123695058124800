import logo from './logo.svg';
import './App.css';
import Schema from './components/schema/Schema';


function App() {
  return <div className="App">
      <Schema/>
    </div>
  
}

export default App;
