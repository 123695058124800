export const literals = {
    "input-1-title":"Nombre",
    "input-1-infoTitle":"Nombre",
    "input-1-infoText":"Introduzca su nombre",
    "input-1-inputPlaceHolder":"Nombre",
    "input-1-inputTitle":"Nombre",
    "input-1-inputAlt":"Nombre",
    "input-1-inputName":"Nombre",
    "input-2-title":"Apellidos",
    "input-2-infoTitle":"Apellidos",
    "input-2-infoText":"Introduzca sus apellidos",
    "input-2-inputPlaceHolder":"Apellidos",
    "input-2-inputTitle":"Apellidos",
    "input-2-iputAlt":"Apellidos",
    "input-2-inputName":"Apellidos",
    "input-3-title":"Teléfono",
    "input-3-infoTitle":"Teléfono",
    "input-3-infoText":"Introduzca su teléfono",
    "input-3-inputPlaceHolder":"Teléfono",
    "input-3-inputTitle":"Teléfono",
    "input-3-iputAlt":"Teléfono",
    "input-3-inputName":"Teléfono",
    "input-4-title":"Sexo",
    "input-4-infoTitle":"Sexo",
    "input-4-infoText":"Introduzca su sexo",
    "input-4-inputPlaceHolder":"Sexo",
    "input-4-inputTitle":"Sexo",
    "input-4-iputAlt":"Sexo",
    "input-4-inputName":"Sexo"

}