import React, { useEffect, useState, useContext, useRef } from "react";
import "./LocationForm.css";
import { literals } from "./Literals";
import { Input_2 } from "../../../Inputs/Input_2/Input_2";
import { Input_5 } from "../../../Inputs/Input_5/Input_5";
import { Input_6 } from "../../../Inputs/Input_6/Input_6";
import { Input_7 } from "../../../Inputs/Input_7/Input_7";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AuthContext } from "../../UserContext";
import { infoCallejero } from "../../infoCallejero";
import axios from "axios";
const LocationForm = ({
  submit,
  setSubmit,
  context,
  setContext,
  setNext,
  filterList,
  setFilterList,
  streets,
  direccionConfirmada = false,
  setDireccionConfirmada = function () {},
  dataUser = false,
}) => {
  const {
    userInfo,
    setUserInfo,
    storageUser,
    citaInfo,
    setCitaInfo,
    storageCita,
  } = useContext(AuthContext);
  const [ok, setOk] = useState({
    input1: false,
    input2: false,
    input3: false,
    input4: false,
    input5: false,
    input6: false,
    input7: false,
  });
  const [okInput1, setOkInput1] = useState(false);
  const [okInput2, setOkInput2] = useState(false);
  const [okInput7, setOkInput7] = useState(false);
  const [okInput8, setOkInput8] = useState(false);
  const [hasChangeInput1, setHasChangeInput1] = useState(false);
  const [hasChangeInput2, setHasChangeInput2] = useState(false);
  const [hasChangeInput3, setHasChangeInput3] = useState(false);
  const [hasChangeInput4, setHasChangeInput4] = useState(false);
  const [hasChangeInput5, setHasChangeInput5] = useState(false);
  const [hasChangeInput6, setHasChangeInput6] = useState(false);
  const [hasChangeInput7, setHasChangeInput7] = useState(false);
  const [hasChangeInput8, setHasChangeInput8] = useState(false);
  const [values, setValues] = useState(false);
  const [callejeroValues, setCallejeroValues] = useState(infoCallejero);

  const [disableCalle, setDisableCalle] = useState(true);
  const [disableNumero, setDisableNumero] = useState(true);
  const [disableEscalera, setDisableEscalera] = useState(true);
  const [disablePiso, setDisablePiso] = useState(true);
  const [disablePuerta, setDisablePuerta] = useState(true);
  const [disableCP, setDisableCP] = useState(true);
  const [disableLocalidad, setDisableLocalidad] = useState(true);
  const [nombreVia, setNombreVia] = useState([]);
  const [dataSetUserInfo, setdataSetUserInfo] = useState(false);
  const dataUserRef = useRef(false);
  const contadorDisparosRef = useRef(0);
  // const [optionsUnique, setOptionsUnique] = useState({});

  function checkCallejero(CP, streetName, tipoVia, localidad, numeroCalle) {
    let found = false;
    // console.log("Check Callejero", CP, streetName);
    for (var i = 0; i < streets.length; i++) {
      let callej = streets[i];
      //if(callej.cp == CP){console.log("callej",streetName,  callej.nombreVia)}
      let checkTipoVia = true;
      if (tipoVia != "") {
        checkTipoVia = checkTipoVia && tipoVia == callej.tipoVia;
      }
      let checkLocalidad = true;
      if (localidad != "") {
        checkLocalidad = localidad == callej.localidad;
      }
      if (
        callej.cp == CP &&
        callej.nombreVia == streetName &&
        callej.cp != "" &&
        checkTipoVia &&
        checkLocalidad
      ) {
        found = true;
        // console.log("Hay coincidencia de CP", callej);
        setCitaInfo({
          ...citaInfo,
          CP: callej.cp,
          uts_addr: callej.nombreVia,
          uts_name: callej.utsNombre,
          uts_localidad: callej.localidad,

          //uts_name:callej.utsNombre,
        });
        if (callej.utsNombre !== "") {
          getUTS(callej.utsNombre, callej.localidad);
        }
        setCallejeroValues({
          ...callejeroValues,
          nombreVia: callej.centroNombre,
          tipoVia: callej.centroNombre,
          localidad: callej.localidad,
          inf: callej.inf,
          sup: callej.sup,
          cp: callej.cp,
          centroNombre: callej.centroNombre,
          utsNombre: callej.utsNombre,
        });
        // if(callejeroValues.utsNombre != ""){
        //   getUTS(callejeroValues.utsNombre )
        // }
        // setValues(okInput8);
        //console.log("cita info al salir", citaInfo)
        //console.log("callejero values al salir", callejeroValues)
        break;
      }
    }
    if (!found) {
      // console.log("Reestableciendo citaInfo, no encontrado");
      // setCitaInfo({});
    }
  }

  function getUTS(utsName, localidad) {
    // console.log("localidad:", localidad);
    // console.log("Obteniendo UTS: ", utsName);
    axios
      .get(process.env.REACT_APP_URL_API + "/Uts/" + utsName, {})
      .then((res) => {
        // console.log("Datos UTS");
        // console.log(res.data);

        const newCitaInfo = {
          uts_id: res.data.idUts,
          uts_telefono: res.data.telefono,
          uts_telefono2: res.data.telefono2,
          uts_fax: res.data.fax,
          uts_pedania: res.data.pedania,
          uts_horario: res.data.horario,
          uts_hora_inicio: res.data.horarioInicio,
          uts_hora_fin: res.data.horarioFin,
          uts_lon: res.data.longitude,
          uts_lat: res.data.latitude,
          uts_name: res.data.nombre,
          uts_addr: res.data.direccion,
          uts_phone: res.data.telefono,
          uts_centro_id: res.data.centroId,
          uts_horario_texto: res.data.horarioTexto,
          uts_localidad: localidad,
        };

        setCitaInfo(newCitaInfo);
        if (newCitaInfo.uts_id !== "" && newCitaInfo.uts_id !== undefined) {
          setUserInfo({
            ...userInfo,
            idUts: newCitaInfo.uts_id,
          });
          localStorage.setItem(
            "SSMURCIA-USER-INFO",
            JSON.stringify({
              ...userInfo,
              idUts: newCitaInfo.uts_id,
            })
          );
          localStorage.setItem(
            "SSMURCIA-CITA-INFO",
            JSON.stringify(newCitaInfo)
          );
          setValues(true);
        } else {
          setValues(false);
        }
      })
      .catch((err) => {});
  }
  useEffect(() => {
    console.log("*/*/*/*Algo ha cambiado");
    setDireccionConfirmada(false);
    userInfo.idUts = 0;
    setUserInfo({ ...userInfo, idUts: 0 });
    setUserInfo(userInfo);
    console.log("USERINFO", userInfo);
    localStorage.setItem("SSMURCIA-USER-INFO", JSON.stringify(userInfo));
  }, [
    hasChangeInput1,
    hasChangeInput2,
    hasChangeInput3,
    hasChangeInput4,
    hasChangeInput5,
    hasChangeInput6,
    hasChangeInput7,
    hasChangeInput8,
  ]);

  useEffect(() => {
    if (context.tipoVia == "") {
      filterList.nombreVia = [];
    }
    if (context.CP == "") {
      filterList.CP = [];
    }
    if (context.localidad == "") {
      filterList.localidad = [];
    }
    setHasChangeInput1(!hasChangeInput1);
  }, []);
  useEffect(() => {
    // if (!dataUser) return;
    if (dataUserRef.current === true) return;
    dataUserRef.current = true;
    const cookieInfo = JSON.parse(localStorage.getItem("SSMURCIA-USER-INFO"));
    const newContext = {
      ...context,
      via: userInfo.via === undefined ? null : userInfo.via,
      calle: userInfo.calle === undefined ? null : userInfo.calle,
      numeroCalle: cookieInfo.numeroCalle,
      escalera: userInfo.escalera,
      piso: userInfo.piso,
      puerta: userInfo.puerta,
      CP: cookieInfo.CP,
      localidad: cookieInfo.localidad,
    };
    setContext(newContext);
    // }
    console.log("************newContext", newContext);

    setDisableCalle(false);
    setDisableNumero(false);
    setDisableEscalera(false);
    setDisablePiso(false);
    setDisablePuerta(false);
    setDisableCP(false);
    setDisableLocalidad(false);
    // console.log("************CONTEXT", context);
  }, [dataSetUserInfo]);

  useEffect(() => {
    console.log("************CONTEXT", context);
    if (context.via === undefined) {
      context.via = null;
    }
    if (context.calle === undefined) {
      context.calle = null;
    }
    setContext(context);
  }, [context]);

  useEffect(() => {
    if (
      ok.input1 &&
      okInput2 &&
      ok.input3 &&
      ok.input4 &&
      ok.input5 &&
      ok.input6 &&
      okInput7 &&
      okInput8
    ) {
      setUserInfo({
        ...userInfo,
        localidad: context.localidad,
        CP: context.CP,
      });
      // console.log("CITAINFO:", citaInfo);

      if (setNext !== undefined) {
        setNext(true);
        setOk({
          input1: false,
          input2: false,
          input3: false,
          input4: false,
          input5: false,
          input6: false,
          input7: false,
          input8: false,
        });
        // console.log("USERINFO:", userInfo);
      }
    } else {
      if (setNext !== undefined) {
        setNext(false);
        setSubmit(false);
      }
    }
  }, [submit, ok]);

  // useEffect(() => {
  //   if (submit) {
  //     return;
  //   }
  //   console.log("SSMURCIA-USER-INFO*****:", citaInfo.uts_id);
  //   if (citaInfo.uts_id !== "" && citaInfo.uts_id !== undefined) {
  //     setUserInfo({ ...userInfo, idUts: citaInfo.uts_id });
  //     console.log("UserInfo", userInfo);
  //     localStorage.setItem(
  //       "SSMURCIA-USER-INFO",
  //       JSON.stringify({
  //         ...userInfo,
  //         idUts: citaInfo.uts_id,
  //       })
  //     );
  //     localStorage.setItem("SSMURCIA-CITA-INFO", JSON.stringify(citaInfo));
  //     setValues(true);
  //   } else {
  //     setValues(false);
  //   }
  // }, [citaInfo]);

  // const checkIfAnyStreetMachInfo = function (citaData) {
  //   if (citaData.calle) {
  //     return streets.some((element) => {
  //       let numero = 0;
  //       if (citaData.numeroCalle) {
  //         numero = citaData.numeroCalle;
  //       }
  //       return element.nombreVia == citaData.calle;
  //     });
  //   } else {
  //     return false;
  //   }
  // };

  useEffect(() => {
    filterList.nombreVia = [];
    if (context.via !== "") {
      let filteredElements = streets.filter((element) => {
        if (element.tipoVia == context.via) {
          filterList.nombreVia.push(element.nombreVia);
        }
      });
    }
    filterList.nombreVia = [...new Set(filterList.nombreVia)];

    // console.log("ENTRA EN EL UNO", dataUserRef.current);

    if (context.via !== null && context.via.length > 0) {
      setDisableCalle(false);
    } else {
      if (dataUser && dataUserRef.current < 1) {
        // console.log("Ha entrado en el primero");
      } else {
        // console.log("Ha entrado en el primero2");
        setContext({ ...context, calle: null });
      }
      setDisableCalle(true);
    }
    setValues(false);
    if (dataUser) {
      if (contadorDisparosRef.current > 1) {
        setContext({ ...context, calle: null });
      }
    } else {
      setContext({ ...context, calle: null });
    }

    setHasChangeInput2(!hasChangeInput2);
  }, [hasChangeInput1]);

  useEffect(() => {
    filterList.CP = [];
    if (context.tipoVia !== "" && context.calle !== "") {
      let filteredElements = streets.filter((element) => {
        if (
          element.tipoVia == context.via &&
          element.nombreVia == context.calle &&
          parseInt(context.numeroCalle) <= parseInt(element.sup) &&
          parseInt(context.numeroCalle) >= parseInt(element.inf)
        ) {
          filterList.CP.push(element.cp.toString());
        }
      });
      setDisableCP(false);
    } else {
      setDisableCP(true);
      setDisableLocalidad(true);
    }
    filterList.CP = [...new Set(filterList.CP)];

    if (dataUser) {
      if (contadorDisparosRef.current >= 2) {
        // console.log("ENTRA EN EL TRES");
        setContext({
          ...context,
          escalera: "",
          piso: "",
          puerta: "",
          CP: "",
          localidad: "",
        });
      }
    } else {
      setContext({
        ...context,
        escalera: "",
        piso: "",
        puerta: "",
        CP: "",
        localidad: "",
      });
    }

    setHasChangeInput7(!hasChangeInput7);
  }, [hasChangeInput3]);

  useEffect(() => {
    filterList.localidad = [];
    // setCitaInfo({});
    if (context.cp !== "") {
      let filteredElements = streets.filter((element) => {
        if (
          element.tipoVia == context.via &&
          element.nombreVia == context.calle &&
          parseInt(context.numeroCalle) <= parseInt(element.sup) &&
          parseInt(context.numeroCalle) >= parseInt(element.inf) &&
          element.cp == context.CP
        ) {
          filterList.localidad.push(element.localidad);
          setDisableLocalidad(false);
        }
      });
    }
    filterList.localidad = [...new Set(filterList.localidad)];

    if (dataUser) {
      if (contadorDisparosRef.current >= 2) {
        if (context.localidad !== null || context.localidad !== "") {
          // context.localidad = "";
          // setHasChangeInput8(!hasChangeInput8);
          setContext({ ...context, localidad: "" });
        }
      }
    } else {
      setContext({ ...context, localidad: "" });
    }
    setHasChangeInput8(!hasChangeInput8);
  }, [hasChangeInput7]);

  useEffect(() => {
    if (dataUser) {
      if (contadorDisparosRef.current < 2) {
        return;
      }
    }
    // console.log("ENTRA EN EL OCHO");
    if (context.localidad === null || context.localidad.length < 1) {
      // setCitaInfo({});
      return;
    }
    checkCallejero(context.CP, context.calle, context.via, context.localidad);
  }, [hasChangeInput8]);

  useEffect(() => {
    // arbol de campos.
    // setCitaInfo(null);

    if (dataUser) {
      if (contadorDisparosRef.current < 2) {
        contadorDisparosRef.current += 1;
        return;
      }
    }
    if (submit) {
      return;
    }
    // console.log("ENTRA EN EL DOS");
    console.log("*** context: ", context);
    if (context.calle !== null && context.calle.length > 0) {
      // cambio
      // console.log("CAMBIO");
      setDisableNumero(false);
      setDisableEscalera(false);
      setDisablePiso(false);
      setDisablePuerta(false);
    } else {
      console.log("ENTRAS POR ESTE?");
      // borrado
      setDisableNumero(true);
      setDisableEscalera(true);
      setDisablePiso(true);
      setDisablePuerta(true);
      setDisableCP(true);
      setDisableLocalidad(true);
    }

    // filterList.nombreVia = [];

    setContext({
      ...context,
      numeroCalle: "",
      escalera: "",
      piso: "",
      CP: "",
      puerta: "",
      localidad: "",
    });
    //setCitaInfo({});

    if (!dataUser && context.calle === null) {
      // if (context.calle.length < 1)
      filterList.CP = [];
      filterList.localidad = [];
      setDisableCP(true);
      setDisableLocalidad(true);
      localStorage.removeItem("SSMURCIA-CITA-INFO");
    }
    setValues(false);
  }, [hasChangeInput2]);
  return (
    <div className="location-form">
      <div className="location-row-1">
        <div className="location-input-1">
          <Input_7
            infoText={literals["input-1-infoText"]}
            infoTitle={literals["input-1-infoTitle"]}
            inputAlt={literals["input-1-inputAlt"]}
            inputName={literals["input-1-inputName"]}
            inputPlaceHolder={literals["input-1-inputPlaceHolder"]}
            inputTitle={literals["input-1-title"]}
            title={literals["input-1-title"]}
            mandatory={true}
            submit={submit}
            context={context}
            setContext={setContext}
            contextName={"via"}
            regex={literals["input-1-regex"]}
            regexText={literals["input-1-regex-text"]}
            hasRegex={true}
            finish={ok}
            setFinish={setOk}
            finishName={"input1"}
            optionsToSelect={streets}
            filterKey={"tipoVia"}
            filterList={filterList}
            hasChange={hasChangeInput1}
            setHasChange={setHasChangeInput1}
          />
        </div>
        <div className="location-input-2">
          <Input_7
            disabled={disableCalle}
            infoText={literals["input-2-infoText"]}
            infoTitle={literals["input-2-infoTitle"]}
            inputAlt={literals["input-2-inputAlt"]}
            inputName={literals["input-2-inputName"]}
            inputPlaceHolder={literals["input-2-inputPlaceHolder"]}
            inputTitle={literals["input-2-title"]}
            title={literals["input-2-title"]}
            mandatory={true}
            submit={submit}
            context={context}
            setContext={setContext}
            contextName={"calle"}
            regex={literals["input-2-regex"]}
            regexText={literals["input-2-regex-text"]}
            hasRegex={true}
            finish={okInput2}
            setFinish={setOkInput2}
            // finishName={"input2"}
            filterKey={"nombreVia"}
            filterList={filterList}
            // value={null}
            hasChange={hasChangeInput2}
            setHasChange={setHasChangeInput2}
          />
          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={null}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Movie" />}
          /> */}
        </div>
      </div>
      <div className="location-row-2">
        <div className="location-input-3">
          <Input_2
            disabled={disableNumero}
            infoText={literals["input-3-infoText"]}
            infoTitle={literals["input-3-infoTitle"]}
            inputAlt={literals["input-3-inputAlt"]}
            inputName={literals["input-3-inputName"]}
            inputPlaceHolder={literals["input-3-inputPlaceHolder"]}
            inputTitle={literals["input-3-title"]}
            title={literals["input-3-title"]}
            mandatory={false}
            submit={submit}
            context={context}
            setContext={setContext}
            contextName={"numeroCalle"}
            hasRegex={false}
            finish={ok}
            setFinish={setOk}
            finishName={"input3"}
            hasChange={hasChangeInput3}
            setHasChange={setHasChangeInput3}
          />
        </div>
        <div className="location-input-3">
          <Input_2
            disabled={disableEscalera}
            infoText={literals["input-4-infoText"]}
            infoTitle={literals["input-4-infoTitle"]}
            inputAlt={literals["input-4-inputAlt"]}
            inputName={literals["input-4-inputName"]}
            inputPlaceHolder={literals["input-4-inputPlaceHolder"]}
            inputTitle={literals["input-4-title"]}
            title={literals["input-4-title"]}
            mandatory={false}
            submit={submit}
            context={context}
            setContext={setContext}
            contextName={"escalera"}
            hasRegex={false}
            finish={ok}
            setFinish={setOk}
            finishName={"input4"}
            hasChange={hasChangeInput4}
            setHasChange={setHasChangeInput4}
          />
        </div>
        <div className="location-input-3">
          <Input_2
            disabled={disablePiso}
            infoText={literals["input-5-infoText"]}
            infoTitle={literals["input-5-infoTitle"]}
            inputAlt={literals["input-5-inputAlt"]}
            inputName={literals["input-5-inputName"]}
            inputPlaceHolder={literals["input-5-inputPlaceHolder"]}
            inputTitle={literals["input-5-title"]}
            title={literals["input-5-title"]}
            mandatory={false}
            submit={submit}
            context={context}
            setContext={setContext}
            contextName={"piso"}
            hasRegex={false}
            finish={ok}
            setFinish={setOk}
            finishName={"input5"}
            hasChange={hasChangeInput5}
            setHasChange={setHasChangeInput5}
          />
        </div>
        <div className="location-input-3">
          <Input_2
            disabled={disablePuerta}
            infoText={literals["input-6-infoText"]}
            infoTitle={literals["input-6-infoTitle"]}
            inputAlt={literals["input-6-inputAlt"]}
            inputName={literals["input-6-inputName"]}
            inputPlaceHolder={literals["input-6-inputPlaceHolder"]}
            inputTitle={literals["input-6-title"]}
            title={literals["input-6-title"]}
            mandatory={false}
            submit={submit}
            context={context}
            setContext={setContext}
            contextName={"puerta"}
            hasRegex={false}
            finish={ok}
            setFinish={setOk}
            finishName={"input6"}
            hasChange={hasChangeInput6}
            setHasChange={setHasChangeInput6}
          />
        </div>
        <div className="location-input-4">
          <Input_7
            disabled={disableCP}
            infoText={literals["input-7-infoText"]}
            infoTitle={literals["input-7-infoTitle"]}
            inputAlt={literals["input-7-inputAlt"]}
            inputName={literals["input-7-inputName"]}
            inputPlaceHolder={literals["input-7-inputPlaceHolder"]}
            inputTitle={literals["input-7-title"]}
            title={literals["input-7-title"]}
            mandatory={true}
            submit={submit}
            context={context}
            setContext={setContext}
            contextName={"CP"}
            regex={literals["input-7-regex"]}
            regexText={literals["input-7-regex-text"]}
            hasRegex={true}
            finish={okInput7}
            filterKey={"CP"}
            filterList={filterList}
            setFinish={setOkInput7}
            hasChange={hasChangeInput7}
            setHasChange={setHasChangeInput7}
            // finishName={"input7"}
          />
        </div>
        <div className="location-input-4">
          <Input_7
            disabled={disableLocalidad}
            infoText={literals["input-8-infoText"]}
            infoTitle={literals["input-8-infoTitle"]}
            inputAlt={literals["input-8-inputAlt"]}
            inputName={literals["input-8-inputName"]}
            inputPlaceHolder={literals["input-8-inputPlaceHolder"]}
            inputTitle={literals["input-8-title"]}
            title={literals["input-8-title"]}
            mandatory={true}
            submit={submit}
            context={context}
            setContext={setContext}
            contextName={"localidad"}
            hasRegex={false}
            finish={okInput8}
            setFinish={setOkInput8}
            // finishName={"input8"}
            filterKey={"localidad"}
            filterList={filterList}
            hasChange={hasChangeInput8}
            setHasChange={setHasChangeInput8}
          />
        </div>
      </div>
      <div hidden={!values}>
        <div className="location-row-3">
          <i className="fa fa-info"></i>
          <label>Le corresponde la UTS</label>
        </div>
        <div className="botton-list-box">
          <ul className="botton-list-box">
            <li hidden={citaInfo.uts_name ? "" : "true"}>
              <label className="bottom-list-text-name">
                <strong>{citaInfo.uts_name}</strong>
              </label>
            </li>
            {/* <li hidden={citaInfo.uts_addr ? "" : "true"}>
              {" "}
              <i className="fa fa-info"></i>
              <label className="bottom-list-text">{citaInfo.uts_addr}</label>
            </li> */}
            {/* <li  hidden={(citaInfo.uts_phone )?"":"true"}><i className="fa fa-phone"></i><label className="bottom-list-text">{citaInfo.uts_phone}</label></li> */}
            {/* <li  hidden={(citaInfo.uts_telefono2 )?"":"true"}><i className="fa fa-phone"></i><label className="bottom-list-text">{citaInfo.uts_telefono2}</label></li> */}
            {/* <li  hidden={(citaInfo.uts_hora_inicio && citaInfo.uts_hora_fin)?"":"true"}><i className="fa fa-calendar"></i><label className="bottom-list-text"><label>Horario de {citaInfo.uts_hora_inicio} hasta las {citaInfo.uts_hora_fin}</label></label></li> */}
            <li hidden={citaInfo.uts_horario_texto ? "" : "true"}>
              <i className="fa fa-calendar"></i>
              <label className="bottom-list-text">
                <label>Horario : {citaInfo.uts_horario_texto}</label>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default LocationForm;
