import React, { useEffect, useState, useContext } from "react";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import "./CambiarCita.css";
import axios from "axios";
import { AuthContext } from "../../../schema/UserContext";

const list = [];

export const CambiarCita = () => {
  const [dates, setDates] = useState(list);
  const {
    userInfo,
    setUserInfo,
    storageUser,
    citaInfo,
    setCitaInfo,
    storageCita,
  } = useContext(AuthContext);
  let distinctDates = {};
  const addDayOfWeekName = function (dateText) {
    const weekday = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const monthNames = [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sept",
      "oct",
      "nov",
      "dic",
    ];
    let splitDate = dateText.split(" ");
    let myMonth = splitDate[0].slice(0, -1);
    let day = splitDate[1].slice(0, -1);
    let year = splitDate[2];
    // console.log("Y-M-D", year, myMonth, day);
    let monthNumber = monthNames.indexOf(myMonth, 0);
    // console.log("monthNumber", monthNumber);
    let myDay = new Date(year, monthNumber, day);
    // console.log(myDay, dateText);
    let dayOfWeek = myDay.getDay();

    let formatedDateText = "";

    let divisionFecha = dateText.split(",");
    let divisionMes = divisionFecha[0].split(" ");
    formatedDateText =
      divisionMes[1] + " " + divisionMes[0] + "." + divisionFecha[1];

    return weekday[dayOfWeek] + " " + formatedDateText;
    //dateText
  };
  useEffect(() => {
    const storageUser = JSON.parse(localStorage.getItem("SSMURCIA-USER-INFO"));
    const storageCita = JSON.parse(localStorage.getItem("SSMURCIA-CITA-INFO"));
    setUserInfo(storageUser);
    setCitaInfo(storageCita);
    console.log("storageUser", storageUser);
    console.log("storageCita", storageCita);
    console.log("storageCita.cita_id", storageUser.idUts);
    axios
      .get(
        process.env.REACT_APP_URL_API +
          "/Agenda/loadAllLibre/" +
          // storageUser.idUts,
          storageUser.idUtsAsignada,
        {}
      )
      .then((res) => {
        // console.log("Respuesta", res);
        setDates(res.data);
      })
      .catch((err) => {});
    // setDates([
    //     {
    //         id: '3',
    //         date: 'Miércoles - 27 de Julio',
    //         day:27,
    //         month:7,
    //       },
    //     {
    //       id: '2',
    //       date: 'Lunes - 01 Agosto',
    //       day:1,
    //       month:8,
    //     },

    //     {
    //         id: '4',
    //         date: 'Martes - 02 de Agosto',
    //         day:2,
    //         month:8,
    //       },
    //   ])
  }, []);

  return (
    <div className="cambiarCita-form">
      <div className="cambiarCita-row-1">
        <div className="cambiarCita-icon">
          <BookmarkIcon
            sx={{
              fontSize: 35,
            }}
          />
        </div>
        <div className="cambiarCita-row-1">
          <div className="cambiarCita-text-1">Servicio de Cita Previa</div>
          <div className="cambiarCita-text-2">Cambiar Cita - Escoger Día</div>
        </div>
      </div>
      <ul className="button-list-box">
        {dates.map((item) => {
          if (distinctDates[item.fecha] != true) {
            distinctDates[item.fecha] = true;

            return (
              <li>
                <button
                  className="button"
                  key={item.fecha}
                  data-index={item}
                  onClick={(e) => {
                    e.preventDefault();
                    // console.log("Valor del boton", item);
                    setCitaInfo({
                      ...citaInfo,
                      idUtsAsignada: item.isUtsAlternative
                        ? item.idUtsAlternativa
                        : item.idUts,
                      agenda_fecha: item.fecha,
                      idUtsAsignado: item.idUts,
                    });
                    localStorage.setItem(
                      "SSMURCIA-CITA-INFO",
                      JSON.stringify({ ...citaInfo, agenda_fecha: item.fecha })
                    );
                    window.location.href = "/CambiarHora";
                  }}
                >
                  <i className="fa fa-fw fa-calendar" />
                  {addDayOfWeekName(item.fecha)} - UTS: {item.utsName}
                </button>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};
