export const infoCallejero={
    nombreVia:"",
    tipoVia:"",
    inf:"",
    sup:"",
    cp:"",
    centroNombre:"",
    utsNombre:"",
    telefono:"",
    localidad:""
}