import { createContext, useState } from "react";
import { infoUser } from "../../../src/components/schema/infoUser";
import { infoCita } from "../../../src/components/schema/infoCita";
export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(infoUser);
  const [citaInfo, setCitaInfo] = useState(infoCita);

  const storageUser = JSON.parse(localStorage.getItem("SSMURCIA-USER-INFO"));
  const storageCita = JSON.parse(localStorage.getItem("SSMURCIA-CITA-INFO"));
  //console.log("Auth provider leyendo los datos");
  const context = {
    userInfo,
    setUserInfo,
    storageUser,
    citaInfo,
    setCitaInfo,
    storageCita
  };
  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
