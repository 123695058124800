import React from "react";
import { useState, useEffect } from "react";
import "./Input_1.css";
// import i
import InfoIcon from "@mui/icons-material/Info";
import { Acordion } from "../../Acordion/Acordion";
// import { wait } from "@testing-library/user-event/dist/utils";
import "react-phone-number-input/style.css";
// import Input from "react-phone-number-input/input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// import es from "react-phone-number-input/locale/es";
import es from "react-phone-input-2/lang/es.json";
import startsWith from "lodash.startswith";

export const Input_phone = ({
  value,
  title,
  infoTitle,
  infoText,
  inputPlaceHolder,
  inputTitle,
  inputAlt,
  inputName,
  mandatory,
  regexText,
  submit,
  context,
  setContext,
  contextName,
  hasRegex,
  regex,
  finish,
  setFinish,
  finishName,
  newInputName,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isMandatory, setIsMandatory] = useState(true);
  const [values, setValues] = useState();
  const [isRegex, setIsRegex] = useState(true);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    //console.log("contexto: ",context[contextName])
    setValues(context[contextName]);
    console.log("Valido?", isValid);
    if (!isValid) {
      console.log("El numero no es valido");
      return;
    }
    if (submit !== undefined) {
      if (
        mandatory &&
        (context[contextName] === undefined ||
          context[contextName] === null ||
          context[contextName] === "")
      ) {
        setIsMandatory(false);
        return;
      }
      if (hasRegex) {
        const regexF = new RegExp(regex);
        if (!regexF.test(values)) {
          setIsRegex(false);
        } else {
          setIsRegex(true);
          finish[finishName] = true;
          setFinish(finish);
        }
      }
    }
  }, [submit]);
  function handleChange(e) {
    const re = /^[0-9\b]+$/;
    if (newInputName === "telefono") {
      if (e === "" || re.test(e)) {
        context[contextName] = e;
        setContext(context);
        setValues(e);
      }
    } else {
      context[contextName] = e;
      setContext(context);
      setValues(e);
    }

    if (
      context[contextName] !== null &&
      context[contextName] !== undefined &&
      context[contextName] === ""
    ) {
      setIsMandatory(true);
    }
  }

  return (
    <div className="input-1-form-box">
      <div className="input-1-column-1">
        <div className="input-1-row-1">
          <label className="input-1-label-1">{title}</label>
          <div
            onClick={() => setIsDisabled(!isDisabled)}
            title="Ayuda Número de documento"
          >
            <InfoIcon
              sx={{
                color: "#c23023",
              }}
            />
          </div>
        </div>
        <PhoneInput
          // className="input-1-box"
          // containerStyle="input-1-box"
          inputStyle={{ width: "100%" }}
          buttonStyle={{ hidden: true }}
          dropdownStyle={{ hidden: true }}
          searchStyle={{ hidden: true }}
          placeholder={inputPlaceHolder}
          title={inputTitle}
          alt={inputAlt}
          name={inputName}
          value={values}
          localization={es}
          country="es"
          regions={"europe"}
          disableDropdown
          disableCountryCode
          enableAreaCodeStretch
          onChange={(e) => {
            // console.log("E", e);
            // handleChange(e.target.value);
            handleChange(e);
          }}
          // maxLength={11}
          disableSearchIcon
          onlyCountries={["es"]}
          // onChange={setValues}
          inputProps={{
            name: "Telefono",
            required: true,
            // autoFocus: true,
          }}
          isValid={(inputNumber, country, countries) => {
            if (inputNumber) {
              if (
                inputNumber[0] === "6" ||
                inputNumber[0] === "7" ||
                inputNumber[0] === "8" ||
                inputNumber[0] === "9"
              ) {
                // console.log("Es valido desde la función");
                setIsValid(true);
              } else {
                // console.log("No válido desde la función");
                setIsValid(false);
              }
            }
            return (
              startsWith(inputNumber, "6", 0) ||
              startsWith(inputNumber, "7", 0) ||
              startsWith(inputNumber, "8", 0) ||
              startsWith(inputNumber, "9", 0)
            );
          }}
        />

        <div
          className={
            isMandatory
              ? "input-1-mandatory-disabled"
              : "input-1-mandatory-text"
          }
        >
          Este campo es obligatorio
        </div>

        <div
          className={
            isValid ? "input-1-mandatory-disabled" : "input-1-mandatory-text"
          }
        >
          El número de telefono debe empezar por 6,7,8 ó 9.
        </div>

        <div
          className={isRegex ? "input-1-regex-disabled" : "input-1-regex-text"}
        >
          {regexText}
        </div>

        <div className="input-1-acordeon">
          <Acordion isDisabled={isDisabled} title={infoTitle} text={infoText} />
        </div>
      </div>
    </div>
  );
};
