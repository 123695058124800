import React from "react";
import { useState, useEffect } from "react";
import "./Input_1.css";
import InfoIcon from "@mui/icons-material/Info";
import { Acordion } from "../../Acordion/Acordion";
import { wait } from "@testing-library/user-event/dist/utils";

export const Input_1 = ({
  value,
  title,
  infoTitle,
  infoText,
  inputPlaceHolder,
  inputTitle,
  inputAlt,
  inputName,
  mandatory,
  regexText,
  submit,
  context,
  setContext,
  contextName,
  hasRegex,
  regex,
  finish,
  setFinish,
  finishName,
  newInputName,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isMandatory, setIsMandatory] = useState(true);
  const [values, setValues] = useState();
  const [isRegex, setIsRegex] = useState(true);

  useEffect(() => {
    //console.log("contexto: ",context[contextName])
    setValues(context[contextName]);
    if (submit != undefined) {
      if (
        mandatory &&
        (context[contextName] === undefined ||
          context[contextName] === null ||
          context[contextName] === "")
      ) {
        setIsMandatory(false);
        return;
      }
      if (hasRegex) {
        const regexF = new RegExp(regex);
        if (!regexF.test(values)) {
          setIsRegex(false);
        } else {
          setIsRegex(true);
          finish[finishName] = true;
          setFinish(finish);
        }
      }
    }
  }, [submit]);
  function handleChange(e) {
    const re = /^[0-9\b]+$/;
    if (newInputName == "telefono") {
      if (e === "" || re.test(e)) {
        context[contextName] = e;
        setContext(context);
        setValues(e);
      }
    } else {
      context[contextName] = e;
      setContext(context);
      setValues(e);
    }

    if (
      context[contextName] !== null &&
      context[contextName] !== undefined &&
      context[contextName] !== ""
    ) {
      setIsMandatory(true);
    }
  }

  return (
    <div className="input-1-form-box">
      <div className="input-1-column-1">
        <div className="input-1-row-1">
          <label className="input-1-label-1">{title}</label>
          <div
            onClick={() => setIsDisabled(!isDisabled)}
            title="Ayuda Número de documento"
          >
            <InfoIcon
              sx={{
                color: "#c23023",
              }}
            />
          </div>
        </div>
        <input
          className="input-1-box"
          placeholder={inputPlaceHolder}
          //   type={newInputName === "telefono" ? "number" : "text"}
          type={"text"}
          title={inputTitle}
          alt={inputAlt}
          name={inputName}
          value={values}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
        />

        <div
          className={
            isMandatory
              ? "input-1-mandatory-disabled"
              : "input-1-mandatory-text"
          }
        >
          Este campo es obligatorio
        </div>
        <div
          className={isRegex ? "input-1-regex-disabled" : "input-1-regex-text"}
        >
          {regexText}
        </div>

        <div className="input-1-acordeon">
          <Acordion isDisabled={isDisabled} title={infoTitle} text={infoText} />
        </div>
      </div>
    </div>
  );
};
