import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Input_1 } from "../../Inputs/Input_1/Input_1";
import { Input_phone } from "../../Inputs/Input_1/Input_phone";
import { AuthContext } from "../UserContext";
import "./Login.css";
import { literals } from "./LoginLiterals";

const Login = () => {
  const { userInfo, setUserInfo } = useContext(AuthContext);

  const [submit, setSubmit] = useState();
  const [ok, setOk] = useState({
    input1: false,
    input2: false,
  });

  useEffect(() => {
    if (ok.input1 && ok.input2) {
      // window.location.href = "/wizard";
      if (ok.input1 && ok.input2) {
        axios
          .post(process.env.REACT_APP_URL_API + "/UsuarioWeb/login", {
            dni: userInfo.DNI,
            telefono: userInfo.telefono,
          })
          .then((res) => {
            // console.log("hoila");
            userInfo.idUts = res.data.idUts;
            userInfo.idUsuarioWeb = res.data.idUsuarioWeb;
            userInfo.nombre = res.data.nombre;
            userInfo.apellidos = res.data.apellidos;
            userInfo.telefono = res.data.telefono;
            userInfo.genero = res.data.sexo;
            // userInfo.via = res.data.localizacionVia;
            // userInfo.calle = res.data.localizacionCalle;
            // userInfo.numeroCalle = res.data.localizacionNumero;
            // userInfo.escalera = res.data.localizacionEscalera;
            // userInfo.piso = res.data.localizacionPiso;
            // userInfo.puerta = res.data.localizacionPuerta;
            // userInfo.CP = res.data.localizacionCp;
            // userInfo.localidad = res.data.localizacionLocalidad;
            userInfo.DNI = res.data.dni;
            userInfo.email = res.data.email;
            if (localStorage.getItem("SSMURCIA-USER-INFO")) {
              localStorage.removeItem("SSMURCIA-USER-INFO");
            }
            localStorage.setItem(
              "SSMURCIA-USER-INFO",
              JSON.stringify(userInfo)
            );
            window.location.href = "/inicio";
          })
          .catch((err) => {
            console.log("Error en login", err);
            if (err.response.status == 404) {
              window.location.href = "/wizard";
            }
          });
      }
    }
  }, [submit]);

  const login = (event) => {
    // event.preventDefault();

    localStorage.setItem("SSMURCIA-USER-INFO", JSON.stringify(userInfo));
    setSubmit(!submit);

    // setUserInfo({ ...userInfo, DNI: DNI });

    // if (userInfo.DNI.length !== 9 && userInfo.telefono !== 9) {
    //   console.log("Error en longitudes");
    //   return;
    // }
    // // if (ok.input1 && ok.input2) {
    // axios
    //   .post(process.env.REACT_APP_URL_API + "/UsuarioWeb/login", {
    //     dni: userInfo.DNI,
    //     telefono: userInfo.telefono,
    //   })
    //   .then((res) => {
    //     console.log("hoila");
    //     userInfo.idUts = res.data.idUts;
    //     userInfo.idUsuarioWeb = res.data.idUsuarioWeb;
    //     userInfo.nombre = res.data.nombre;
    //     userInfo.apellidos = res.data.apellidos;
    //     userInfo.telefono = res.data.telefono;
    //     userInfo.genero = res.data.sexo;
    //     userInfo.via = res.data.localizacionVia;
    //     userInfo.calle = res.data.localizacionCalle;
    //     userInfo.numeroCalle = res.data.localizacionNumero;
    //     userInfo.escalera = res.data.localizacionEscalera;
    //     userInfo.piso = res.data.localizacionPiso;
    //     userInfo.puerta = res.data.localizacionPuerta;
    //     userInfo.CP = res.data.localizacionCp;
    //     userInfo.localidad = res.data.localizacionLocalidad;
    //     userInfo.DNI = res.data.dni;
    //     userInfo.email = res.data.email;
    //     localStorage.setItem("SSMURCIA-USER-INFO", JSON.stringify(userInfo));
    //     console.log("Encontrado");
    //     // window.location.href = "/inicio";
    //   })
    //   .catch((err) => {
    //     console.log("Error en login", err);
    //     if (err.response.status == 404) {
    //       console.log("NO Encontrado");
    //       // window.location.href = "/wizard";
    //     }
    //   });
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      // console.log("User pressed: ", event.key);

      if (event.key === "Enter") {
        event.preventDefault();
        // 👇️ call submit function here
        login();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  return (
    <div className="centered">
      <div className="login-box">
        <div className="login-text-1">
          <label className="login-title">CITA PREVIA POR INTERNET</label>
        </div>
        <div className="login-text-1">
          <label className="login-title-2">Lunes a domingo las 24 horas </label>
        </div>
        <div className="login-text-2">
          <label className="login-text">
            Para solicitar cita con su trabajador/a social tiene que introducir
            los siguientes datos:
          </label>
        </div>
        <div className="login-input-boxes">
          <Input_1
            title={literals["input-1-title"]}
            infoTitle={literals["input-1-infoTitle"]}
            infoText={literals["input-1-infoText"]}
            inputPlaceHolder={literals["input-1-inputPlaceHolder"]}
            inputTitle={literals["input-1-inputTitle"]}
            inputAlt={literals["input-1-inputAlt"]}
            inputName={literals["input-1-inputName"]}
            context={userInfo}
            setContext={setUserInfo}
            contextName="DNI"
            mandatory={true}
            submit={submit}
            regex={literals["input-1-regex"]}
            hasRegex={true}
            regexText={literals["input-1-regex-text"]}
            finish={ok}
            setFinish={setOk}
            finishName={"input1"}
            newInputName={"dni"}
            inputRef={(input) => input && input.focus()}
            inputProps={{
              name: "DNI",
              required: true,
              autoFocus: true,
            }}
          />
          <Input_phone
            title={literals["input-2-title"]}
            infoTitle={literals["input-2-infoTitle"]}
            infoText={literals["input-2-infoText"]}
            inputPlaceHolder={literals["input-2-inputPlaceHolder"]}
            inputTitle={literals["input-2-inputTitle"]}
            inputAlt={literals["input-2-iputAlt"]}
            inputName={literals["input-2-inputName"]}
            context={userInfo}
            setContext={setUserInfo}
            contextName="telefono"
            mandatory={true}
            submit={submit}
            regex={literals["input-2-regex"]}
            hasRegex={true}
            regexText={literals["input-2-regex-text"]}
            finish={ok}
            setFinish={setOk}
            finishName={"input2"}
            newInputName={"telefono"}
          />
          <button
            className="login-button"
            type="submit"
            onClick={(e) => login(e)}
          >
            ENTRAR
          </button>
        </div>
      </div>
    </div>
  );
};
export default Login;
