export const infoCita = {
    uts_id: "",
    uts_telefono : "",
    uts_telefono2 : "", 
    uts_fax : "", 
    uts_pedania : "",
    uts_horario : "", 
    uts_hora_inicio : "", 
    uts_hora_fin:"", 
    uts_lon : "", 
    uts_lat : "",
    CP:"",
    uts_name:"",
    uts_addr:"",
    uts_phone:"",
    agenda_fecha : "",
    agenda_hora : "",
    uts_centro_id:"",
    cita_id:"",
    uts_localidad:"",
    isPresencial:true,
};
  