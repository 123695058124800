import React from 'react'
import {useState, useEffect} from "react"
import './Input_4.css'
import InfoIcon from '@mui/icons-material/Info'
import { Acordion } from '../../Acordion/Acordion'


export const Input_4 = ({value,title, infoTitle, infoText, inputPlaceHolder, inputTitle,inputAlt,inputName,mandatory,submit,context,setContext,contextName,regex,hasRegex,regexText,finish,setFinish,finishName}) => {
    const [isDisabled, setIsDisabled] = useState(true)
    const [isMandatory, setIsMandatory] = useState(true)
    const [isRegex, setIsRegex] = useState(true)
    const [values, setValues] = useState();

    useEffect(() =>{
        setValues(context[contextName])
    }, [context])

    useEffect(() => {
        setValues(context[contextName])
        //console.log("input-button")
        if(submit!=undefined){
        if(mandatory && (context[contextName]===undefined || context[contextName]===null || context[contextName]==='')){
            setIsMandatory(false)
            if(setFinish!==undefined){
               finish[finishName]=false
                setFinish(finish)
            }
            return;
        }
        if(hasRegex){
            const regexF = new RegExp(regex)
            if(!regexF.test(values)){
                console.log("sadfasdfsdaffsdaafsdfd")
                    setIsRegex(false)
            }else{
                setIsRegex(true)
                if(setFinish!==undefined){
                finish[finishName]=true
                setFinish(finish)

                }
            }
        }else{
                finish[finishName]=true
                if(setFinish!==undefined){

                setFinish(finish)
                }

        }
        }
    }, [submit])
    
    function handleChange(e){
        // console.log("e ",e)
        // console.log("context: ",context)
        context[contextName]=e;
        setContext(context)
        setValues(e)

        if(context[contextName]!==null && context[contextName]!==undefined && context[contextName]!==''){
            setIsMandatory(true)
        }
    }
    return (
        <div  className="input_4-form-box">
            <div className="input_4-column-1">
                <div className="input_4-row-1">
                    <label className="input_4-label-1"> 
                        {title}
                    </label>
                    <div onClick={() => setIsDisabled(!isDisabled)} title="Ayuda Número de documento">
                        <InfoIcon sx={{
                            color:'#c23023'
                        }}/>
                    </div>
                </div>
                <select onChange={(event)=>{  event.preventDefault(); setContext({...context, genero:event.target.value})}}  
                  value={context.genero} mandatory={true} className="select-box">
                    <option value="" disabled >Sexo</option>
                    <option value="Hombre">Hombre</option>
                    <option value="Mujer">Mujer</option>
                    <option value="No especificado">No especificado</option>
                </select>

                <div className={isMandatory?'input_4-mandatory-disabled':'input_4-mandatory-text'} >
                    Este campo es obligatorio
                </div>
                <div className={isRegex?'input_4-regex-disabled':'input_4-regex-text'} >
                    {regexText}
                </div>
                <div className="input_4-acordeon">
                <Acordion isDisabled={isDisabled} title= {infoTitle}text={infoText}/>
                </div>
            </div>
        </div>
    )
}
