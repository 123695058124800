import React, {useState} from "react";
import { literals } from "./Literals";
import "./AccessForm.css";
import { Input_2 } from "../../../Inputs/Input_2/Input_2";

const AccessForm = ({ submit, context, setContext }) => {

  return (
    <div className="access-form">
      <div className="access-row-1">
        <div className="acces-input-1">
          <Input_2
            infoText={literals["input-1-infoText"]}
            infoTitle={literals["input-1-infoTitle"]}
            inputAlt={literals["input-1-inputAlt"]}
            inputName={literals["input-1-inputName"]}
            inputPlaceHolder={literals["input-1-inputPlaceHolder"]}
            inputTitle={literals["input-1-title"]}
            title={literals["input-1-title"]}
            mandatory={true}
            submit={submit}
            context={context}
            setContext={setContext}
            contextName={"DNI"}

          />
        </div>
        <div className="acces-input-1">
          <Input_2
            infoText={literals["input-2-infoText"]}
            infoTitle={literals["input-2-infoTitle"]}
            inputAlt={literals["input-2-inputAlt"]}
            inputName={literals["input-2-inputName"]}
            inputPlaceHolder={literals["input-2-inputPlaceHolder"]}
            inputTitle={literals["input-2-title"]}
            title={literals["input-2-title"]}
            mandatory={false}
            submit={submit}
            context={context}
            setContext={setContext}
            contextName={"email"}
  
          />
        </div>
      </div>
    </div>
  );
};
export default AccessForm;
