export const literals = {
  "input-1-title": "Vía",
  "input-1-infoTitle": "Vía",
  "input-1-infoText": "Introduzca el tipo de vía",
  "input-1-inputPlaceHolder": "Vía",
  "input-1-inputTitle": "Vía",
  "input-1-inputAlt": "Vía",
  "input-1-inputName": "Vía",
  "input-1-regex": "^.{2,}$",
  "input-1-regex-text": "Porfavor introduzce 2 letras mínimo",
  "input-2-title": "Calle",
  "input-2-infoTitle": "Calle",
  "input-2-infoText": "Introduzca el nombre de la calle",
  "input-2-inputPlaceHolder": "Calle",
  "input-2-inputTitle": "Calle",
  "input-2-iputAlt": "Calle",
  "input-2-inputName": "Calle",
  "input-2-regex": "^.{2,}$",
  "input-2-regex-text": "Porfavor introduzce 4 letras mínimo",
  "input-3-title": "Número",
  "input-3-infoTitle": "Número",
  "input-3-infoText": "Introduzca el número",
  "input-3-inputPlaceHolder": "Número",
  "input-3-inputTitle": "Número",
  "input-3-iputAlt": "Número",
  "input-3-inputName": "Número",
  "input-4-title": "Escalera",
  "input-4-infoTitle": "Escalera",
  "input-4-infoText": "Introduzca la escalera",
  "input-4-inputPlaceHolder": "Escalera",
  "input-4-inputTitle": "Escalera",
  "input-4-iputAlt": "Escalera",
  "input-4-inputName": "Escalera",
  "input-5-title": "Piso",
  "input-5-infoTitle": "Piso",
  "input-5-infoText": "Introduzca el piso",
  "input-5-inputPlaceHolder": "Piso",
  "input-5-inputTitle": "Piso",
  "input-5-inputAlt": "Piso",
  "input-5-inputName": "Piso",
  "input-6-title": "Puerta",
  "input-6-infoTitle": "Puerta",
  "input-6-infoText": "Introduzca la puerta",
  "input-6-inputPlaceHolder": "Puerta",
  "input-6-inputTitle": "Puerta",
  "input-6-inputAlt": "Puerta",
  "input-6-inputName": "Puerta",
  "input-7-title": "Código Postal",
  "input-7-infoTitle": "Código Postal",
  "input-7-infoText": "Introduzca el código postal",
  "input-7-inputPlaceHolder": "Código Postal",
  "input-7-inputTitle": "Código Postal",
  "input-7-iputAlt": "Código Postal",
  "input-7-inputName": "Código Postal",
  "input-7-regex": "^\\d{5}$",
  "input-7-regex-text": "Porfavor introduzca 5 números para el código postal ",
  "input-8-title": "Localidad",
  "input-8-infoTitle": "Localidad",
  "input-8-infoText":
    "Es necesario que seleccione su localidad para que podamos indicar la UTS que le corresponde",
  "input-8-inputPlaceHolder": "Localidad",
  "input-8-inputTitle": "Localidad",
  "input-8-inputAlt": "Localidad",
  "input-8-inputName": "Localidad",
};
