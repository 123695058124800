export const literals = {
  "input-1-title": "DNI/NIF/NIE/Pasaporte",
  "input-1-infoTitle": "DNI/NIF/NIE/Pasaporte",
  "input-1-infoText":
    "Introduzca su número de documento de identidad sin espacios ni guiones, por ejemplo 12345678z Documento Nacional de Identidad (DNI) Número de Identificación Fiscal (NIF) Número de Identificación de Extranjeros (NIE) Pasaporte ",
  "input-1-inputPlaceHolder": "DNI/NIF/NIE/Pasaporte",
  "input-1-inputTitle": "Número de documento",
  "input-1-inputAlt": "Número de documento",
  "input-1-inputName": "DNI",
  "input-1-regex":
    "^[0-9]{8,8}[A-Za-z]?$|^[a-zA-Z]{2}[a-zA-z]?[0-9]{6}[a-zA-Z]?$|^[xyzXYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]$",
  "input-1-regex-text": "Porfavor introduzca un DNI, NIE o pasaporte correctos",
  "input-2-title": "Teléfono",
  "input-2-infoTitle": "Teléfono",
  "input-2-infoText": "Introduzca su número de contacto, por ejemplo 639111222",
  "input-2-inputPlaceHolder": "Teléfono",
  "input-2-inputTitle": "Teléfono de contacto",
  "input-2-iputAlt": "Teléfono de contacto",
  "input-2-inputName": "Teléfono",
  "input-2-regex": "^\\d{9}$",
  // "input-2-regex":"(\+34|0034|34)?[ -]*(6|7)[ -]*([0-9][ -]*){8}", // original
  // "input-2-regex": "*(6|7|9)[ -]*([0-9][ -]*){8}",
  "input-2-regex-text": "Porfavor introduzca un número de teléfono correcto",
};
