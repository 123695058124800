import { Grid, Typography } from "@mui/material";
import React from "react";
import "./Header.css";
//import logo from "public/logo-top.png";
let today = new Date();
let date = today.toLocaleString("en-us", { day: "numeric" });
let month = today.toLocaleString("en-us", { month: "short" }).toUpperCase();
let year = today.getFullYear() % 1000;

const Header = () => {
  return (
    <div className="headerbox">
      <div className="headerbox-left-box">
        <a href="https://citaprevia.serviciossociales.murcia.es/login">
          <img
            src="/logo-top-1.png"
            className="logo-top-1"
            alt="logo-top.png"
          />
        </a>
      </div>
      <div className="headerbox-right-text">
        <div className="title-logo">
          <h2 style={{ margin: 0 }}>Servicios Sociales</h2>
          <a href="https://citaprevia.serviciossociales.murcia.es/login">
            <img
              src="/logo-top-4.png"
              className="logo-top-2"
              alt="logo-top.png"
            />
          </a>
        </div>
        <div className="header-datetime-box">
          <span>
            <label className="header-datetime-date-text top-date">{date}</label>
          </span>
          <span className="header-datetime-month-text">
            <label className="top-date">{month}</label>
          </span>
          <span className="header-datetime-year-text">
            <label className="top-date">{year}</label>
          </span>
        </div>
      </div>
    </div>
  );
};
export default Header;
