import React, { useContext, useEffect, useState } from "react";
import AccessForm from "../../schema/Wizard/AccessForm/AccessForm";
import LocationForm from "../../schema/Wizard/LocationForm/LocationForm";
import UserForm from "../../schema/Wizard/UserForm/UserForm";
import "./DatosUsuario.css";
import "../Inicio.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AuthContext } from "../../schema/UserContext";
import axios from "axios";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Button from "@mui/material/Button";
import { Report } from "notiflix";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { ConfirmDialog } from "../../schema/sharedComponents/confirmDialog";
import GroupsIcon from "@mui/icons-material/Groups";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import EmailIcon from "@mui/icons-material/Email";
import Modal from "react-modal";
import { infoCita } from "../../schema/infoCita";

export const DatosUsuario = () => {
  const {
    userInfo,
    setUserInfo,
    storageUser,
    citaInfo,
    setCitaInfo,
    storageCita,
  } = useContext(AuthContext);
  // useEffect(() => {
  //   if (userInfo.DNI === = "") {
  //     //console.log("Leyendo storageUser", storageUser);
  //     setUserInfo(storageUser);
  //   }
  // }, []);
  const [idUser, setIdUser] = useState(userInfo.idUsuarioWeb);
  const [exit, setExit] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [streets, setStreets] = useState([]);
  const [optionsUnique, setOptionsUnique] = useState({});
  const [citaDisabled, setCitaDisabled] = useState(true);
  const [dateInfo, setDateInfo] = useState("");
  const [direccionConfirmada, setDireccionConfirmada] = useState(false);
  const [emailButtonDisabled, setEmailButtonDisabled] = useState(false);

  const getFilteredDistinctOptions = function (optionsToFilter) {
    setOptionsUnique({});
    for (let filterKey in optionsToFilter[0]) {
      optionsUnique[filterKey] = [
        ...new Set(optionsToFilter.map((item) => item[filterKey])),
      ].sort();
      setOptionsUnique(optionsUnique);
      // const unique = [...new Set(optionsToFilter.map(item => item[filterKey]))]; // [ 'A', 'B']
    }
    // console.log("Options Unique", optionsUnique);
  };
  function getCallejero() {
    //console.log("Obteniendo CALLEJERO: ");
    if (streets.length === 0) {
      axios
        .get(process.env.REACT_APP_URL_API + "/Callejero/all", {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((res) => {
          //console.log(res)
          //console.log(res.body)
          // console.log("Calles", res.data);
          setStreets(res.data);
          getFilteredDistinctOptions(res.data);
          setDataLoaded(true);
          //  window.location.href = "/inicio";
        })
        .catch((err) => {});
    }
  }
  useEffect(() => {
    if (localStorage.getItem("SSMURCIA-USER-INFO") === null) {
      window.location.href = "/login";
      return;
    }
  }, []);
  useEffect(() => {
    if (streets.length === 0) {
      // console.log("Cargando callejero");
      getCallejero();
    }
    if (!exit) {
      // console.log("Borrando datos de userInfo", userInfo, storageUser);
      //setUserInfo( storageUser)
      if (userInfo.idUsuarioWeb === "") {
        //console.log("Obteniendo de local storage", userInfo, storageUser)
        setUserInfo(storageUser);
        //console.log("Actualizando idUser de", idUser, " a ",userInfo.idUsuarioWeb);
        setIdUser(storageUser.idUsuarioWeb);
      }
      if (idUser === "" && userInfo.idUsuarioWeb !== "") {
        //console.log("Actualizando idUser de", idUser, " a ",userInfo.idUsuarioWeb);

        setIdUser(userInfo.idUsuarioWeb);
      }
      // console.log("Obteniendo datos idUser:" + idUser, userInfo);

      axios
        .get(process.env.REACT_APP_URL_API + "/UsuarioWeb/" + idUser, {})
        .then((res) => {
          // console.log("Actualizando userInfo, respuesta:", res);
          setUserInfo({
            idUts: res.data.idUts,
            idUsuarioWeb: idUser,
            nombre: res.data.nombre,
            apellidos: res.data.apellidos,
            telefono: res.data.telefono,
            genero: res.data.sexo,
            via: res.data.localizacionVia,
            calle: res.data.localizacionNombre,
            numeroCalle: res.data.localizacionNumero,
            escalera: res.data.localizacionEscalera,
            piso: res.data.localizacionPiso,
            puerta: res.data.localizacionPuerta,
            CP: res.data.localizacionCp,
            localidad: res.data.localizacionLocalidad,
            DNI: res.data.dni,
            email: res.data.email,
          });

          localStorage.setItem("SSMURCIA-USER-INFO", JSON.stringify(userInfo));
          // console.log("Actualizado userInfo:", userInfo);
          if (idUser === "" && userInfo.idUsuarioWeb !== "") {
            //console.log("Actualizando idUser de", idUser, " a ",userInfo.idUsuarioWeb);
            setIdUser(userInfo.idUsuarioWeb);
          }
        })
        .catch((err) => {
          //console.log("Error obteniendo los datos del usuario idUser:", idUser, userInfo)
          if (idUser === "" && userInfo.idUsuarioWeb !== "") {
            //console.log("Actualizando idUser de", idUser, " a ",userInfo.idUsuarioWeb);
            setIdUser(userInfo.idUsuarioWeb);
          }
        });
    }
  }, [idUser]);

  function getUTS(utsID) {
    // console.log("Obteniendo UTS: ", "/Uts/byId/" + utsID);
    //const map = useMap();
    axios
      .get(process.env.REACT_APP_URL_API + "/Uts/byId/" + utsID, {})
      .then((res) => {
        // console.log("UTS:", res.data);
        if (!citaInfo.cita_id) {
          setCitaInfo({
            ...citaInfo,
            uts_id: utsID,
            uts_telefono: res.data.telefono,
            uts_telefono2: res.data.telefono2,
            uts_fax: res.data.fax,
            uts_pedania: res.data.pedania,
            uts_horario: res.data.horario,
            uts_hora_inicio: res.data.horarioInicio,
            uts_hora_fin: res.data.horarioFin,
            uts_lon: res.data.longitude,
            uts_lat: res.data.latitude,
            uts_name: res.data.nombre,
            uts_addr: res.data.direccion,
            uts_phone: res.data.telefono,
            uts_centro_id: res.data.centroId,
          });
        }
        localStorage.setItem("SSMURCIA-USER-INFO", JSON.stringify(userInfo));
        localStorage.setItem("SSMURCIA-CITA-INFO", JSON.stringify(citaInfo));
        //console.log("citaInfo", citaInfo)
        //  window.location.href = "/inicio";
      })
      .catch((err) => {
        // console.log("Problema obteniendo UTS - ", err);
      });
  }
  const actualizarUsuario = () => {
    setExit(true);
    //console.log("Actualizando: idUsuarioWeb:",userInfo.idUsuarioWeb);
    if (idUser === "" || idUser === "" || idUser === null) {
      //console.log("Actualizando idUser de", idUser, " a ",userInfo.idUsuarioWeb);
      setIdUser(userInfo.idUsuarioWeb);
    }
    let id;
    if (idUser !== "") {
      id = idUser;
    } else if (userInfo.idUsuarioWeb !== "") {
      id = userInfo.idUsuarioWeb;
    } else {
      const storageUser = JSON.parse(
        localStorage.getItem("SSMURCIA-USER-INFO")
      );
      id = storageUser.idUsuarioWeb;
    }

    // console.log("A actualizar", userInfo.idUts, userInfo, storageUser);
    // console.log("Citainfo iduts", citaInfo.uts_id, userInfo.idUts);
    axios
      .post(process.env.REACT_APP_URL_API + "/UsuarioWeb/update", {
        idUts: citaInfo.uts_id,
        idUsuarioWeb: id,
        dni: userInfo.DNI,
        telefono: userInfo.telefono,
        nombre: userInfo.nombre,
        apellidos: userInfo.apellidos,
        email: userInfo.email,
        // localizacionNombre: userInfo.calle,
        // localizacionTipo: userInfo.via,
        // localizacionCp: userInfo.CP,
        // localizacionVia: userInfo.via,
        // localizacionNumero: userInfo.numeroCalle,
        // localizacionEscalera: userInfo.escalera,
        // localizacionPiso: userInfo.piso,
        // localizacionPuerta: userInfo.puerta,
        // localizacionLocalidad: userInfo.localidad,
        sexo: userInfo.genero,
      })
      .then((res) => {
        // console.log("Update success");
        // console.log(res.data);
        // console.log(userInfo);
        //console.log(res.body)
        //console.log(res.data)
        ////userInfo.idUsuarioWeb=res.data.idUsuarioWeb
        // if(idUser==="" && userInfo.idUsuarioWeb !== ""){
        //   //console.log("Actualizando idUser de", idUser, " a ",userInfo.idUsuarioWeb);
        //   setIdUser(userInfo.idUsuarioWeb);
        // }
        userInfo["idUts"] = citaInfo.uts_id;
        setUserInfo(userInfo);
        localStorage.setItem("SSMURCIA-USER-INFO", JSON.stringify(userInfo));
        localStorage.setItem("SSMURCIA-CITA-INFO", JSON.stringify(citaInfo));
        Report.success(
          "Datos",
          "Datos de usuario actualizados correctamente",
          "Cerrar"
        );
        // window.location.href = "/inicio";
      })
      .catch((err) => {
        // setExit(false);
        //console.log("Error en el update", userInfo)
        //console.log("idUser", idUser)
        //console.log(err)
        //console.log(err.response)
        if (idUser === "" && userInfo.idUsuarioWeb !== "") {
          //console.log("Actualizando idUser de", idUser, " a ",userInfo.idUsuarioWeb);
          //setIdUser(userInfo.idUsuarioWeb);
          //actualizarUsuario()
        }
      });
  };
  const actualizarDireccion = () => {
    setExit(true);
    //console.log("Actualizando: idUsuarioWeb:",userInfo.idUsuarioWeb);
    if (idUser === "" || idUser === "" || idUser === null) {
      //console.log("Actualizando idUser de", idUser, " a ",userInfo.idUsuarioWeb);
      setIdUser(userInfo.idUsuarioWeb);
    }
    let id;
    if (idUser !== "") {
      id = idUser;
    } else if (userInfo.idUsuarioWeb !== "") {
      id = userInfo.idUsuarioWeb;
    } else {
      const storageUser = JSON.parse(
        localStorage.getItem("SSMURCIA-USER-INFO")
      );
      id = storageUser.idUsuarioWeb;
    }

    // console.log("A actualizar", userInfo.idUts, userInfo, storageUser);
    // console.log("Citainfo iduts", citaInfo.uts_id, userInfo.idUts);
    axios
      .post(process.env.REACT_APP_URL_API + "/UsuarioWeb/update", {
        idUts: citaInfo.uts_id,
        idUsuarioWeb: id,
        // dni: userInfo.DNI,
        // telefono: userInfo.telefono,
        // nombre: userInfo.nombre,
        // apellidos: userInfo.apellidos,
        // email: userInfo.email,
        localizacionNombre: userInfo.calle,
        localizacionTipo: userInfo.via,
        localizacionCp: userInfo.CP,
        localizacionVia: userInfo.via,
        localizacionNumero: userInfo.numeroCalle,
        localizacionEscalera: userInfo.escalera,
        localizacionPiso: userInfo.piso,
        localizacionPuerta: userInfo.puerta,
        localizacionLocalidad: userInfo.localidad,
        // sexo: userInfo.genero,
      })
      .then((res) => {
        // console.log("Update success");
        // console.log(res.data);
        // console.log(userInfo);
        //console.log(res.body)
        //console.log(res.data)
        ////userInfo.idUsuarioWeb=res.data.idUsuarioWeb
        // if(idUser==="" && userInfo.idUsuarioWeb !== ""){
        //   //console.log("Actualizando idUser de", idUser, " a ",userInfo.idUsuarioWeb);
        //   setIdUser(userInfo.idUsuarioWeb);
        // }
        userInfo["idUts"] = citaInfo.uts_id;
        userInfo["idUtsAsignadaHasToUpdate"] = true;

        setUserInfo(userInfo);
        localStorage.setItem("SSMURCIA-USER-INFO", JSON.stringify(userInfo));
        localStorage.setItem("SSMURCIA-CITA-INFO", JSON.stringify(citaInfo));
        // window.location.href = "/inicio";
      })
      .catch((err) => {
        setExit(false);
        //console.log("Error en el update", userInfo)
        //console.log("idUser", idUser)
        //console.log(err)
        //console.log(err.response)
        if (idUser === "" && userInfo.idUsuarioWeb !== "") {
          //console.log("Actualizando idUser de", idUser, " a ",userInfo.idUsuarioWeb);
          //setIdUser(userInfo.idUsuarioWeb);
          //actualizarUsuario()
        }
      });
  };
  const [submit, setSubmit] = useState();
  useEffect(() => {
    if (userInfo.idUsuarioWeb !== "" && userInfo.idUts !== "") {
      getUTS(userInfo.idUts);
    }
  }, [userInfo.idUts]);

  // desde inicio
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isPresencial, setIsPresencial] = useState(false);
  const [regexOk, setRegexOk] = useState(true);
  // const [dateInfo, setDateInfo] = useState("");
  const [idAgenda, setIdAgenda] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [emailProblem, setEmailProblem] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const sendEmailButtonText_notSend = "Enviar por e-mail";
  const sendEmailButtonText_send = "E-mail ya enviado";
  function openModal() {
    setIsOpen(true);
  }
  const [sendEmailText, setSendEmailText] = useState(
    sendEmailButtonText_notSend
  );
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeModal() {
    setEmailProblem(false);
    setRegexOk(true);
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  useEffect(() => {
    if (localStorage.getItem("SSMURCIA-USER-INFO") === null) {
      window.location.href = "/login";
      return;
    }
    const storageUser = JSON.parse(localStorage.getItem("SSMURCIA-USER-INFO"));
    const storageCita = JSON.parse(localStorage.getItem("SSMURCIA-CITA-INFO"));

    setUserInfo(storageUser);
    setCitaInfo(storageCita);
    console.log("storageUser", storageUser);
    console.log("storageCita", storageCita);
    axios
      .get(
        process.env.REACT_APP_URL_API +
          "/Agenda/loadFromUsarioWeb/" +
          storageUser.idUsuarioWeb,
        {}
      )
      .then((res) => {
        console.log("Hay agenda?", res);
        if (res.data === null || res.data.length === 0) {
          console.log("No hay agenda", infoCita);
          setCitaInfo(infoCita);
        } else {
          console.log("Hay agenda - ", res.data);
          let agenda = res.data[0];
          console.log(agenda);

          // setCitaInfo({
          //   idAgenda : agenda.idAgenda,

          // })
          console.log("Agenda id", agenda);
          setIdAgenda(agenda.idAgenda);
          getUTS(agenda.idUts);
          setCitaInfo({
            ...citaInfo,
            cita_id: agenda.idAgenda,
            agenda_fecha: agenda.fecha,
            agenda_hora: agenda.hora,
            isPresencial: agenda.presencial,
          });
          setIsPresencial(agenda.presencial);
          function cambiarFecha(fecha) {
            let formatedDateText = "";

            let divisionFecha = fecha.split(",");
            let divisionMes = divisionFecha[0].split(" ");

            formatedDateText =
              divisionMes[1] + " " + divisionMes[0] + "." + divisionFecha[1];
            return formatedDateText;
          }

          function cambiarHora(hora) {
            let posPoints = hora.lastIndexOf(":");
            let sustituir = hora.substring(posPoints, hora.length);
            let formatedTime = hora.replace(sustituir, " Horas");
            return formatedTime;
          }
          let fecha = cambiarFecha(agenda.fecha);
          let hora = cambiarHora(agenda.hora);

          agenda.fecha = fecha;
          agenda.hora = hora;

          setDateInfo(
            "Tiene una cita el día " + agenda.fecha + " a las " + agenda.hora
          );
        }
      })
      .catch((err) => {
        console.log("Problema consultando agenda", err);
      });
  }, []);

  console.log("DATEINFO!", dateInfo);
  if (dateInfo !== "") {
    console.log("TENEMOS UTS ID ASIGNADA?", userInfo.utsID);
    getUTS(userInfo.utsID);
  }
  return (
    <div className="datos-usuario-box">
      <div className="inicio-cita" hidden={dateInfo !== "" ? "" : true}>
        <div className="pedirCita-row-2">
          <div className="pedirCita-row-2-header-box">
            <div>
              <i className="fa fa-info"></i>

              <label className="infoBef pedirCita-row-2-header-text">
                La cita reservada con su trabajador/a Social:
              </label>
            </div>
            <div class="col-md-6">
              <p>
                <i className="fa fa-user"></i>
                <b className="pedirCita-row-2-info">
                  {userInfo.nombre + " " + userInfo.apellidos}
                </b>
              </p>
              <p>
                <i className="fa fa-calendar"></i>
                <b className="pedirCita-row-2-info">{dateInfo}</b>
              </p>
              <p>
                <i className="fa fa-hospital-o"></i>
                <b className="pedirCita-row-2-info">
                  Unidad de Trabajo Social(UTS):
                </b>{" "}
                {citaInfo !== null ? citaInfo.uts_name : ""}
              </p>
              {/* <p hidden={citaInfo !== null && citaInfo.uts_telefono ? "" : true}>
            <i className="fa fa-phone"></i>
            <b className="pedirCita-row-2-info">
              Teléfono de la UTS:{" "}
            </b>{" "}
            {citaInfo !== null ? citaInfo.uts_telefono : ""}
          </p>
          <p
            hidden={citaInfo !== null && citaInfo.uts_telefono2 ? "" : true}
          >
            <i className="fa fa-phone"></i>
            <b className="pedirCita-row-2-info">
              Teléfono de la UTS:{" "}
            </b>{" "}
            {citaInfo !== null ? citaInfo.uts_telefono2 : ""}
          </p>
          <p hidden={citaInfo !== null && citaInfo.uts_fax ? "" : true}>
            <i className="fa fa-phone"></i>
            <b className="pedirCita-row-2-info">FAX de la UTS: </b>{" "}
            {citaInfo !== null ? citaInfo.uts_fax : ""}
          </p>*/}
              <p>
                <i className="fa fa-book"></i>
                <b className="pedirCita-row-2-info">
                  Dirección de la UTS:{" "}
                </b>{" "}
                {citaInfo !== null ? citaInfo.uts_addr : ""}
              </p>
              <p>
                <i className="fa fa-book"></i>
                <b className="pedirCita-row-2-info"></b>La cita es{" "}
                {isPresencial === true ? "presencial" : "telefónica"}
              </p>
            </div>
            <div className="inicio-row-2">
              <div className="inicio-button-2">
                <Button
                  variant="outlined"
                  startIcon={<CalendarMonthIcon />}
                  sx={{
                    color: "#fff",
                    backgroundColor: "#c23023",
                    borderColor: "#c23023",
                    padding: "0.9rem 6rem",
                    height: "100%",
                    width: "100%",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenConfirm(true);
                  }}
                >
                  Cancelar
                </Button>
                <ConfirmDialog
                  open={openConfirm}
                  setOpen={setOpenConfirm}
                  onClose={() => {
                    setOpenConfirm(false);
                  }}
                  onConfirm={() => {
                    axios
                      .post(
                        process.env.REACT_APP_URL_API + "/Agenda/cancelar",
                        {
                          idAgenda: idAgenda,
                          idUsuario: userInfo.idUsuarioWeb,
                        }
                      )
                      .then((res) => {
                        console.log(
                          "Exito cancelando",
                          citaInfo.cita_id,
                          res.data
                        );
                        setCitaInfo({});
                        setDateInfo("");
                        setIdAgenda("");
                        Report.success(
                          "¡Bien!",
                          "Reserva cancelada correctamente",
                          "Cerrar",
                          () => {
                            window.location.href = "/Inicio";
                          }
                        );
                        // window.location.href = "/Inicio";
                      })
                      .catch((err) => {
                        console.log("Problema cancelando", err);
                      });
                  }}
                >
                  ¿Cancelar la reserva?
                </ConfirmDialog>
              </div>
              <div className="inicio-button-2">
                <Button
                  variant="outlined"
                  startIcon={<EmailIcon />}
                  disabled={emailButtonDisabled}
                  sx={{
                    color: "#fff",
                    backgroundColor: "#c23023",
                    borderColor: "#c23023",
                    padding: "0.9rem 6rem",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(e);
                  }}
                >
                  {sendEmailText}
                </Button>
                <Modal
                  isOpen={modalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <h2>Enviar correo</h2>
                  {/* <button onClick={closeModal}>close</button> */}
                  <div>El correo se enviará a la dirección:</div>
                  <div>
                    <input
                      className="inicio-input-box"
                      type="text"
                      defaultValue={userInfo.email}
                      onChange={(e) => {
                        userInfo.email = e.target.value;
                        console.log("Guardando nuevo email ", userInfo.email);
                        setUserInfo({ ...userInfo, email: e.target.value });
                      }}
                    ></input>
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#c23023",
                        borderColor: "#c23023",
                        padding: "0.4rem 0.25rem",
                        "&:hover": {
                          backgroundColor: "#e53023",
                        },
                      }}
                      onClick={() => {
                        console.log("Email a usar : ", userInfo.email);
                        let regex =
                          "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
                        const regexF = new RegExp(regex);
                        if (regexF.test(userInfo.email)) {
                          setRegexOk(true);
                          console.log(
                            process.env.REACT_APP_URL_API + "/Agenda/confirm",
                            idAgenda,
                            userInfo.email
                          );
                          console.log(citaInfo);
                          console.log(storageCita);
                          //let url = "serviciosocial/api/v1.0/Agenda/confirm/{idAgenda}"
                          //let storageCita = JSON.parse(localStorage.getItem("SSMURCIA-CITA-INFO"));
                          console.log(
                            process.env.REACT_APP_URL_API + "/Agenda/confirm",
                            idAgenda,
                            userInfo.email
                          );
                          console.log(citaInfo);
                          console.log(storageCita);
                          axios
                            .post(
                              process.env.REACT_APP_URL_API + "/Agenda/confirm",
                              { idAgenda: idAgenda, email: userInfo.email }
                            )
                            .then((res) => {
                              //axios.get(process.env.REACT_APP_URL_API + '/Agenda/confirm/' + idAgenda, {
                              //}).then((res) => {
                              console.log("Correo enviado con éxito");
                              setEmailProblem(false);
                              localStorage.setItem(
                                "SSMURCIA-USER-INFO",
                                JSON.stringify(userInfo)
                              );
                              setSendEmailText(sendEmailButtonText_send);
                              closeModal();
                            })
                            .catch((err) => {
                              console.log("Correo enviado");
                              setSendEmailText(sendEmailButtonText_notSend);
                              setEmailProblem(true);
                            });
                        } else {
                          setRegexOk(false);
                        }
                      }}
                    >
                      Enviar E-Mail
                    </Button>
                  </div>
                  <div>
                    {emailProblem
                      ? "Ha ocurrido un problema enviando el E-Mail"
                      : ""}
                    {!regexOk ? "Formato de E-Mail incorrecto" : ""}
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="datos-usuario-text-box">
        <div className="datos-usuario-icon">
          <InfoOutlinedIcon
            sx={{
              fontSize: 35,
            }}
          />
        </div>
        <div className="datos-usuario-text">
          <div className="datos-usuario-text-1">
            Servicio de Cita Previa - {userInfo.DNI}
          </div>
          <div className="datos-usaurio-text-2">Datos de Usuario(a)</div>
        </div>
      </div>
      <div className="datos-usuario-form-1">
        <AccessForm
          submit={submit}
          context={userInfo}
          setContext={setUserInfo}
        />

        <UserForm submit={submit} context={userInfo} setContext={setUserInfo} />

        <button
          className="datos-usuario-button-1"
          type="submit"
          onClick={(e) => {
            actualizarUsuario();
          }}
        >
          Guardar Datos Personales
        </button>
        {dataLoaded ? (
          <LocationForm
            submit={submit}
            context={userInfo}
            setContext={setUserInfo}
            filterList={optionsUnique}
            streets={streets}
            dataUser={true}
            direccionConfirmada={direccionConfirmada}
            setDireccionConfirmada={setDireccionConfirmada}
          />
        ) : (
          "Cargando..."
        )}
      </div>
      <div className="datos-usuario-form-2">
        <div className="datos-usuario-bottom-buttons">
          {direccionConfirmada ? (
            <button
              className="datos-usuario-button-1"
              startIcon={<CalendarMonthIcon />}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/PedirCita";
              }}
              disabled={dateInfo === "" ? "" : true}
            >
              {" "}
              Pedir Cita
            </button>
          ) : (
            <button
              className="datos-usuario-button-1"
              type="submit"
              onClick={(e) => {
                console.log("******Hay utsinfo", userInfo.idUts);
                if (
                  userInfo.idUts === undefined ||
                  userInfo.idUts === null ||
                  userInfo.idUts === 0
                ) {
                  console.log("No valido");
                  Report.failure("Datos", "Debe rellenar los campos", "Cerrar");
                  return;
                }
                if (dateInfo !== "") {
                  Report.failure(
                    "Cita",
                    "Ya tiene una cita asignada, debea anularla antes de obtener otra",
                    "Cerrar"
                  );
                  return;
                }
                actualizarDireccion();
                Report.success(
                  "Datos",
                  "Dirección actualizada, puede solicitar cita",
                  "Cerrar"
                );
                setDireccionConfirmada(true);
              }}
            >
              Confirmar dirección
            </button>
          )}
          <button
            className="datos-usuario-button-2"
            type="submit"
            onClick={(e) => {
              console.log("QUE USERWEB TENEMOS?", userInfo.idUsuarioWeb);
              axios
                .post(process.env.REACT_APP_URL_API + "/UsuarioWeb/update", {
                  idUts: 0,
                  idUsuarioWeb: storageUser.idUsuarioWeb,
                  dni: userInfo.DNI,
                  telefono: userInfo.telefono,
                  nombre: userInfo.nombre,
                  apellidos: userInfo.apellidos,
                  email: userInfo.email,
                  localizacionNombre: "", //userInfo.calle,
                  localizacionTipo: "", //userInfo.via,
                  localizacionCp: "", //userInfo.CP,
                  localizacionVia: "", //userInfo.via,
                  localizacionNumero: "", //userInfo.numeroCalle,
                  localizacionEscalera: "", //userInfo.escalera,
                  localizacionPiso: "", //userInfo.piso,
                  localizacionPuerta: "", //userInfo.puerta,
                  localizacionLocalidad: "", //userInfo.localidad,
                  sexo: userInfo.genero,
                })
                .then((res) => {
                  let cleanInfo = {};
                  localStorage.setItem(
                    "SSMURCIA-USER-INFO",
                    JSON.stringify(cleanInfo)
                  );
                  localStorage.setItem(
                    "SSMURCIA-CITA-INFO",
                    JSON.stringify(cleanInfo)
                  );
                  localStorage.removeItem(
                    "SSMURCIA-USER-INFO"
                    // JSON.stringify(cleanInfo)
                  );
                  localStorage.removeItem(
                    "SSMURCIA-CITA-INFO"
                    // JSON.stringify(cleanInfo)
                  );
                  console.log("resultado", res);
                  window.location.href = "/Login";
                })
                .catch((err) => console.log("Erro", err));
            }}
          >
            Cerrar sesión
          </button>
        </div>
      </div>
    </div>
    // </>
  );
};
