import "./Footer.css";

const Footer = () => {
  return (
    <div className="footerbox">
      <div className="footerbox-left-box">
        <img
          src="/logo-footer.png"
          className="logo-footer"
          alt="logo-footer.png"
        />
      </div>
      <div className="footerbox-right-box">

        <div className="footer-text-box">
          <div className="footerbox-right-text-1">
            <a className="footer-right-text-a-1" href="http://murcia.es/web/portal/aviso-legal" title="Aviso legal" target="_blank">
              Aviso legal
            </a>
            <li className="footer-right-text-li-2"/>
            <a className="footer-right-text-a-2" href="http://murcia.es/web/portal/aviso-legal" title="Política de privacidad" target="_blank">
                Política de privacidad y cookies
            </a>
          </div>
          <div className="footer-content">
            <div className="footer-right-text-2">
                <strong className="footer-right-text-strong-1">
                  © Copyright Ayuntamiento de Murcia. Glorieta de España 1, 30004 Murcia. España
                </strong>
              <strong className="footer-right-text-strong-2">
                  Tlf. 968 35 86 00 
              </strong>
            </div>
            <div className="iconImagen-1">
            <img className="img-responsive-1" src="images/wcag.png" alt="wcag"></img>
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  );
};
export default Footer;
