export const literals = {
    "input-1-title":"DNI/NIF/NIE/Pasaporte",
    "input-1-infoTitle":"DNI/NIF/NIE/Pasaporte",
    "input-1-infoText":"Introduzca su número de documento de identidad sin espacios ni guiones, por ejemplo 12345678z Documento Nacional de Identidad (DNI) Número de Identificación Fiscal (NIF) Número de Identificación de Extranjeros (NIE) Pasaporte ",
    "input-1-inputPlaceHolder":"DNI/NIF/NIE/Pasaporte",
    "input-1-inputTitle":"Número de documento",
    "input-1-inputAlt":"Número de documento",
    "input-1-inputName":"DNI",
    "input-2-title":"E-mail",
    "input-2-infoTitle":"E-mail",
    "input-2-infoText":"Introduzca el email",
    "input-2-inputPlaceHolder":"E-mail",
    "input-2-inputTitle":"E-mail",
    "input-2-iputAlt":"E-mail",
    "input-2-inputName":"E-mail"


}