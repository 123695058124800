import React from 'react'
import {useState, useEffect} from "react"
import './Input_6.css'
import InfoIcon from '@mui/icons-material/Info'
import { Acordion } from '../../Acordion/Acordion'
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();
export const Input_6 = ({value,title, 
    infoTitle, infoText, inputPlaceHolder, 
    inputTitle,inputAlt,inputName,mandatory,
    submit,context,setContext,contextName,
    regex,hasRegex,regexText,finish,
    setFinish, filterList, filterKey}) => {
    
    const [isDisabled, setIsDisabled] = useState(true)
    const [isMandatory, setIsMandatory] = useState(true)
    const [isRegex, setIsRegex] = useState(true)
    const [values, setValues] = useState();
    const [optionsUnique, setOptionsUnique] = useState([])


    useEffect(() =>{
        setOptionsUnique(filterList[filterKey])
        setValues(context[contextName])

    }, [context])

    useEffect(() => {
        setValues(context[contextName])
        if(submit!=undefined){
        if(mandatory && (context[contextName]===undefined || context[contextName]===null || context[contextName]==='')){
            setIsMandatory(false)
            if(setFinish!==undefined){
            
                setFinish(false)
            }
            return;
        }
        if(hasRegex){
            const regexF = new RegExp(regex)
            if(!regexF.test(values)){
                console.log("sadfasdfsdaffsdaafsdfd")
                setIsRegex(false)
            }else{
                setIsRegex(true)
                if(setFinish!==undefined){
                  setFinish(true)
                }
            }
        }else{
                if(setFinish!==undefined){
                  setFinish(true)
                }
        }
        }
    }, [submit])
    
    function handleChange(e){
        // console.log("e ",e)
        // console.log("context: ",context)
        context[contextName]=e;
        setContext(context)
        setValues(e)
        //console.log("input-button", e, values)
        if(mandatory && (context[contextName]===undefined || context[contextName]===null || context[contextName]==='')){
            setIsMandatory(false)
            if(setFinish!==undefined){
                setFinish(false)
            }
            return;
        }
        if(hasRegex){
            const regexF = new RegExp(regex)
            if(!regexF.test(e)){
                //console.log("sadfasdfsdaffsdaafsdfd")
                //setIsRegex(false)
                if(finish){
                    setFinish(false)
                    setIsRegex(false)
                }
            }else{
                console.log("Finish")
                setIsRegex(true)
                console.log("Finish")
                setFinish(true)
                
            }
        }else{
                if(setFinish!==undefined){
                    console.log("Finish")
                    setFinish(true)
                }

        }
        
        
        if(context[contextName]!==null && context[contextName]!==undefined && context[contextName]!==''){
            setIsMandatory(true)
        }
    }

    return (
        <div  className="input-6-form-box">
            <div className="input-6-column-1">
                <div className="input-6-row-1">
                    <label className="input-6-label-1"> 
                        {title}
                    </label>
                    <div onClick={() => setIsDisabled(!isDisabled)} title="Ayuda Número de documento">
                        <InfoIcon sx={{
                            color:'#c23023'
                        }}/>
                    </div>
                </div>

                    <Autocomplete
                    //className="input-6-box"
                    noOptionsText="No hay sugerencias"
                    disablePortal
                    id="combo-box-demo"
                    autoHighlight={true}
                    options={optionsUnique.map((option) => option)}
                    sx={{ 
                        color: "#bbb",
                        width:"100%",
                        height:"34px",
                        overflow: "auto",
                        opacity: 1,
                        fontSize: "14px",
                        lineHeight: 1.42857143,
                        fontSize: "18px",
                        color:"#333",
                        fontFamily: "Roboto, sans-serif",
                        fontWeight: 700,
                        color: "#333",
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
                    }}
                    renderInput={(params) => <TextField {...params} label="" />}
                    />
                <div className={isMandatory?'input-6-mandatory-disabled':'input-6-mandatory-text'} >
                    Este campo es obligatorio
                </div>
                <div className={isRegex?'input-6-regex-disabled':'input-6-regex-text'} >
                    {regexText}
                </div>
                <div className="input-6-acordeon">
                <Acordion isDisabled={isDisabled} title= {infoTitle}text={infoText}/>
                </div>
            </div>
        </div>
    )
}
