import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login/Login";
import Wizard from "./Wizard/Wizard";
import { AuthContext } from "./UserContext";
import { Inicio } from "../Inicio/Inicio";
import { DatosUsuario } from "../Inicio/DatosUsuario/DatosUsuario";
import { PedirCita } from "../Inicio/PedirCita/PedirCita";
import { CambiarHora } from "../Inicio/PedirCita/CambiarCita/CambiarHora/CambiarHora";
import { CambiarCita } from "../Inicio/PedirCita/CambiarCita/CambiarCita";
import { ConfirmarCita } from "../Inicio/PedirCita/ConfirmarCita/ConfirmarCita";
import { PageNotRunning } from "./PageNotRunning";
const Navigation = () => {
  const { userInfo } = useContext(AuthContext);
  return (
    <Router>
      <Routes>
        {/* <Route
          exact
          path="*"
          element={<PageNotRunning />}
        />

        <Route
          exact
          path="/"
          element={ <PageNotRunning />}
        />
        <Route exact path="/Login" element={<PageNotRunning />} />
        <Route exact path="/Login" element={<PageNotRunning />} />
        <Route exact path="/Wizard" element={<PageNotRunning />} />
        <Route exact path="/inicio" element={<PageNotRunning />} />
        <Route exact path="/datosUsuario" element={<PageNotRunning />} />
        <Route exact path="/PedirCita" element={<PageNotRunning />} />
        <Route exact path="/CambiarCita" element={<PageNotRunning />} />
        <Route exact path="/CambiarHora" element={<PageNotRunning />} />
        <Route exact path="/ConfirmarCita" element={<PageNotRunning />} /> */}
        <Route
          exact
          path="*"
          // element={!userInfo.telefono ? <Login /> : <Inicio />}
          element={!userInfo.telefono ? <Login /> : <DatosUsuario />}
        />

        <Route
          exact
          path="/"
          // element={!userInfo.telefono ? <Login /> : <Inicio />}
          element={!userInfo.telefono ? <Login /> : <DatosUsuario />}
        />
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/Wizard" element={<Wizard />} />
        {/* <Route exact path="/inicio" element={<Inicio />} /> */}
        <Route exact path="/inicio" element={<DatosUsuario />} />
        <Route exact path="/datosUsuario" element={<DatosUsuario />} />
        <Route exact path="/PedirCita" element={<PedirCita />} />
        <Route exact path="/CambiarCita" element={<CambiarCita />} />
        <Route exact path="/CambiarHora" element={<CambiarHora />} />
        <Route exact path="/ConfirmarCita" element={<ConfirmarCita />} />
        <Route exact path="/PageNotRunning" element={<PageNotRunning />} />
      </Routes>
    </Router>
  );
};
export default Navigation;
