import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";

export const MapObject = ({ coordinates, zoom, textoMarker }) => {
  console.log(
    "Creando mapa con coordenadas ",
    coordinates,
    "textoMarker",
    textoMarker,
    "zoom",
    zoom
  );
  return (
    <MapContainer
      center={coordinates}
      zoom={zoom}
      style={{ height: "90%", width: "90%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={coordinates}>
        <Popup>{textoMarker}</Popup>
      </Marker>
    </MapContainer>
  );
};
