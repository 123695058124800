
import "./PageNotRunning.css"


export const PageNotRunning = () => {

    return <div className="pageNotRunning-center-label-box">
            <label className="pageNotRunning-labels"><strong>Estamos realizando mejoras.</strong></label>
            <label className="pageNotRunning-labels"><strong>Esta  página no va a estar  en funcionamiento hasta el 1 de septiembre de 2022.</strong></label>
            <label className="pageNotRunning-labels"><strong>Para solicitar cita llame al Teléfono: <a href="tel:+34868080300">868 080 300</a> de lunes a viernes de 9:00 a 14:00 horas.</strong></label>
        </div>
}