import React from 'react'
import './Acordion.css'
import InfoIcon from '@mui/icons-material/Info';

export const Acordion = ({isDisabled,title,text}) => {
    return (
            <div className={isDisabled?'disabled':'acordion'} >
                    <div className="row-1">
                        <InfoIcon className="icon" color="primary" sx={{
                            color:'#444444',
                            backgroundColor:'white',
                        }}/>
                        <div className="acordion-title">
                            {title}
                        </div>
                    </div>
                    <div className="acordion-text-box">
                        <p className="acordion-text">
                            {text}
                        </p>
                    </div>
            </div>
    )

}

