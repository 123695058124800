import React from "react";
import { useState, useEffect } from "react";
import "./Input_2.css";
import InfoIcon from "@mui/icons-material/Info";
import { Acordion } from "../../Acordion/Acordion";

export const Input_2 = ({
  value,
  title,
  infoTitle,
  infoText,
  inputPlaceHolder,
  inputTitle,
  inputAlt,
  inputName,
  mandatory,
  submit,
  context,
  setContext,
  contextName,
  regex,
  hasRegex,
  regexText,
  finish,
  setFinish,
  finishName,
  disabled,
  hasChange,
  setHasChange,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isMandatory, setIsMandatory] = useState(true);
  const [isRegex, setIsRegex] = useState(true);
  const [values, setValues] = useState();

  useEffect(() => {
    // console.log("LOG context");
    setValues(context[contextName]);
  }, [context]);

  useEffect(() => {
    setValues(context[contextName]);
    hasChange = false;
  }, [hasChange]);

  // console.log("INPUT_2", context, values);
  useEffect(() => {
    setValues(context[contextName]);
    //console.log("input-button")
    if (submit !== undefined) {
      if (
        mandatory &&
        (context[contextName] === undefined ||
          context[contextName] === null ||
          context[contextName] === "")
      ) {
        setIsMandatory(false);
        if (setFinish !== undefined) {
          finish[finishName] = false;
          setFinish(finish);
          // setFinish({ ...finish, finishName: false });
        }
        if (setHasChange !== undefined) {
          setHasChange(false);
        }
        return;
      }
      if (hasRegex) {
        const regexF = new RegExp(regex);
        if (!regexF.test(values)) {
          setIsRegex(false);
        } else {
          setIsRegex(true);
          if (setFinish !== undefined) {
            finish[finishName] = true;
            setFinish(finish);
            // setFinish({ ...finish, finishName: false });
          }
        }
      } else {
        if (setFinish !== undefined) {
          // console.log("NOMBRE:", finishName);
          if (finish !== null) {
            // console.log("Que tiene finish?", finish);
            finish[finishName] = true;
            setFinish(finish);
          } else {
            alert("ERROR AL SET FINISH");
          }
          // setFinish({ ...finish, finishName: false });
        }
        if (setHasChange !== undefined) {
          setHasChange(!hasChange);
        }
      }
    }
  }, [submit]);

  function handleChange(e) {
    // console.log("e ",e)
    // console.log("context: ",context)
    context[contextName] = e;
    setContext(context);
    setValues(e);
    if (setHasChange !== undefined) {
      setHasChange(!hasChange);
    }
    if (
      context[contextName] !== null &&
      context[contextName] !== undefined &&
      context[contextName] !== ""
    ) {
      setIsMandatory(true);
    }
  }

  return (
    <div className="input-2-form-box">
      <div className="input-2-column-1">
        <div className="input-2-row-1">
          <label className="input-2-label-1">{title}</label>
          <div
            onClick={() => setIsDisabled(!isDisabled)}
            title="Ayuda Número de documento"
          >
            <InfoIcon
              sx={{
                color: "#c23023",
              }}
            />
          </div>
        </div>
        <input
          disabled={disabled}
          className="input-2-box"
          placeholder={inputPlaceHolder}
          type="text"
          title={inputTitle}
          alt={inputAlt}
          name={inputName}
          value={values}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
        />

        <div
          className={
            isMandatory
              ? "input-2-mandatory-disabled"
              : "input-2-mandatory-text"
          }
        >
          Este campo es obligatorio
        </div>
        <div
          className={isRegex ? "input-2-regex-disabled" : "input-2-regex-text"}
        >
          {regexText}
        </div>
        <div className="input-2-acordeon">
          <Acordion isDisabled={isDisabled} title={infoTitle} text={infoText} />
        </div>
      </div>
    </div>
  );
};
