import React, { useContext,useEffect } from "react";
import {AuthContext} from "../../UserContext";
import { Input_2 } from "../../../Inputs/Input_2/Input_2";
import { Input_3 } from "../../../Inputs/Input_3/Input_3";
import { Input_4 } from "../../../Inputs/Input_4/Input_4";
import "./UserForm.css";

import { literals } from "./Literals";
import { useState } from "react";

const UserForm = ({  submit, context, setContext,setNext }) => {
  
  const [ok,setOk] = useState({
      input1:false,
      input2:false,
      input3:false,
      input4:false
    });

  useEffect(() => {
    //console.log("entra")

    if(ok.input1 && ok.input2 && ok.input3 && ok.input4){
      //console.log("hola?")
      if(setNext!==undefined){

      setNext(true)
      }
    }else{
      if(setNext!==undefined){

      setNext(false)
      }
    }
  }, [submit,ok])
  
  return (
    <div className="user-form-box">
      <div className="user-form-row-1">
        <Input_2
          infoText={literals["input-1-infoText"]}
          infoTitle={literals["input-1-infoTitle"]}
          inputAlt={literals["input-1-inputAlt"]}
          inputName={literals["input-1-inputName"]}
          inputPlaceHolder={literals["input-1-inputPlaceHolder"]}
          inputTitle={literals["input-1-title"]}
          title={literals["input-1-title"]}
          mandatory={true}
          submit={submit}
          context={context}
          setContext={setContext}
          contextName={"nombre"}
          finish={ok}
          setFinish={setOk}
          finishName={"input1"}
        />
        <Input_2
          infoText={literals["input-2-infoText"]}
          infoTitle={literals["input-2-infoTitle"]}
          inputAlt={literals["input-2-inputAlt"]}
          inputName={literals["input-2-inputName"]}
          inputPlaceHolder={literals["input-2-inputPlaceHolder"]}
          inputTitle={literals["input-2-title"]}
          title={literals["input-2-title"]}
          mandatory={true}
          submit={submit}
          context={context}
          setContext={setContext}
          contextName={"apellidos"}
          finish={ok}
          setFinish={setOk}
          finishName={"input2"}

        />
      </div>
      <div className="user-form-row-2">
        <Input_2
          infoText={literals["input-3-infoText"]}
          infoTitle={literals["input-3-infoTitle"]}
          inputAlt={literals["input-3-inputAlt"]}
          inputName={literals["input-3-inputName"]}
          inputPlaceHolder={literals["input-3-inputPlaceHolder"]}
          inputTitle={literals["input-3-title"]}
          title={literals["input-3-title"]}
          mandatory={false}
          submit={submit}
          context={context}
          setContext={setContext}
          contextName={"telefono"}
          finish={ok}
          setFinish={setOk}
          finishName={"input3"}

        />

         <Input_4
          infoText={literals["input-4-infoText"]}
          infoTitle={literals["input-4-infoTitle"]}
          inputAlt={literals["input-4-inputAlt"]}
          inputName={literals["input-4-inputName"]}
          inputPlaceHolder={literals["input-4-inputPlaceHolder"]}
          inputTitle={literals["input-4-title"]}
          title={literals["input-4-title"]}
          mandatory={true}
          submit={submit}
          context={context}
          setContext={setContext}
          contextName={"genero"}
          finish={ok}
          setFinish={setOk}
          finishName={"input4"}

        /> 
     
      </div>
    </div>
  );
};
export default UserForm;
