import BookmarkIcon from "@mui/icons-material/Bookmark";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import axios from "axios";
import { Report } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../schema/UserContext";
import { ConfirmDialog } from "../../schema/sharedComponents/confirmDialog";
import { MapObject } from "./MapObject";
import "./PedirCita.css";

export const PedirCita = () => {
  const textNoCita = "No hay citas disponibles para esta UTS.";
  const [coordinates, setCoordinates] = useState([]);
  const [map, setMap] = useState(null);
  const [partialCita, setPartialCita] = useState({});
  const [textoMarker, setTextoMarker] = useState("UTS");
  const [isPresencial, setIsPresencial] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [alternateAppointment, setAlternateApointment] = useState(false);
  const [alternateIdUts, setAlternateIdUts] = useState("");
  const [assignedUTSName, setAssignedUTSName] = useState("");
  const {
    userInfo,
    setUserInfo,
    storageUser,
    citaInfo,
    setCitaInfo,
    storageCita,
  } = useContext(AuthContext);
  const [dateInfo, setDateInfo] = useState("");

  useEffect(() => {
    if (coordinates != [] && coordinates.length > 0 && coordinates[0] !== "") {
      // console.log("Actualizando textoMarker");
      setTextoMarker(
        <div>
          <label>
            El centro <strong>{citaInfo.uts_name}</strong> está en :<br />{" "}
            <strong>
              <a
                target="_blank"
                href={
                  "https://www.openstreetmap.org/?mlat=" +
                  coordinates[0] +
                  "&mlon=" +
                  coordinates[1]
                }
              >
                {citaInfo.uts_addr}
              </a>
            </strong>
          </label>
        </div>
      );
    }
  }, coordinates);
  function getUTS(utsID) {
    // console.log("Obteniendo UTS: ", "/Uts/byId/" + utsID);
    //const map = useMap();
    axios
      .get(process.env.REACT_APP_URL_API + "/Uts/byId/" + utsID, {})
      .then((res) => {
        // console.log("UTS:", res.data);
        if (!citaInfo.cita_id) {
          setCitaInfo({
            ...citaInfo,
            uts_telefono: res.data.telefono,
            uts_telefono2: res.data.telefono2,
            uts_fax: res.data.fax,
            uts_pedania: res.data.pedania,
            uts_horario: res.data.horario,
            uts_hora_inicio: res.data.horarioInicio,
            uts_hora_fin: res.data.horarioFin,
            uts_lon: res.data.longitude,
            uts_lat: res.data.latitude,
            uts_horario_texto: res.data.horarioTexto,
            uts_name: res.data.nombre,
            uts_addr: res.data.direccion,
            uts_phone: res.data.telefono,
            uts_centro_id: res.data.centroId,
          });
          let lon = res.data.longitude;
          let lat = res.data.latitude;
          if (lat !== undefined && lat !== null && lat !== "" && lon !== "") {
            try {
              let coord = [parseFloat(lat) / 10, parseFloat(lon) / 10];
              // console.log("Estableciendo coordenadas ", lon, lat);
              setTextoMarker(
                <div>
                  <label>
                    El centro <strong>{citaInfo.uts_name}</strong> está en :
                    <br />{" "}
                    <strong>
                      <a
                        target="_blank"
                        href={
                          "https://www.openstreetmap.org/?mlat=" +
                          coord[0] +
                          "&mlon=" +
                          coord[1]
                        }
                      >
                        {res.data.direccion}
                      </a>
                    </strong>
                  </label>
                </div>
              );
              setCoordinates(coord);
            } catch {
              // console.log("Coordenadas vacías");
            }
          }
        }

        //setCoordinates(res.data.longitude, res.data.latitude)
        //console.log("Actualizando mapa")
        //goToCoordinates(res.data.longitude, res.data.latitude)
        //console.log("citaInfo", citaInfo)
        //  window.location.href = "/inicio";
      })
      .catch((err) => {
        // console.log("Problema obteniendo UTS - ", err);
      });
  }
  function reservar() {
    // console.log("PARTIAL CITA:", partialCita);
    // console.log("RESERVANDO", citaInfo, userInfo, partialCita.cita_id);
    // const storageUser = JSON.parse(localStorage.getItem("SSMURCIA-USER-INFO"));

    // console.log(
    //   "Datos reserva",
    //   partialCita.cita_id,
    //   userInfo.idUsuarioWeb,
    //   citaInfo.isPresencial
    // );
    axios
      .post(process.env.REACT_APP_URL_API + "/Agenda/reservar", {
        idAgenda: partialCita.cita_id,
        idUsuario: userInfo.idUsuarioWeb,
        presencial: isPresencial,
        localizacionCp: userInfo.CP,
        localizacionEscalera: userInfo.escalera,
        localizacionLocalidad: userInfo.localidad,
        localizacionNombre: userInfo.calle,
        localizacionNumero: userInfo.numeroCalle,
        localizacionPiso: userInfo.piso,
        localizacionPuerta: userInfo.puerta,
        localizacionTipo: userInfo.via,
        localizacionVia: userInfo.via,
      })
      .then((res) => {
        // console.log(
        //   "Exito reservando",
        //   partialCita.cita_id,
        //   res.data,
        //   citaInfo.isPresencial
        // );
        setCitaInfo({
          ...citaInfo,
          cita_id: partialCita.cita_id,
          agenda_fecha: partialCita.agenda_fecha,
          agenda_hora: partialCita.agenda_hora,
        });
        localStorage.setItem(
          "SSMURCIA-CITA-INFO",
          JSON.stringify({
            ...citaInfo,
            cita_id: partialCita.cita_id,
            agenda_fecha: partialCita.agenda_fecha,
            agenda_hora: partialCita.agenda_hora,
          })
        );
        setTimeout(function () {
          window.location.href = "/ConfirmarCita";
        }, 200);
      })
      .catch((err) => {
        // console.log("Problema reservando", err);
      });
  }
  useEffect(() => {
    if (localStorage.getItem("SSMURCIA-USER-INFO") === null) {
      window.location.href = "/login";
      return;
    }
    let storageUser = JSON.parse(localStorage.getItem("SSMURCIA-USER-INFO"));
    let storageCita = JSON.parse(localStorage.getItem("SSMURCIA-CITA-INFO"));
    // console.log("STORAGE CITA:", storageCita);
    // console.log("STORAGE USER:", storageUser);
    setAssignedUTSName(storageCita.uts_name);

    // CONTROLAR LA LLAMADA
    if (storageCita && storageCita.idAgenda) {
      // console.log("primera condicion ");
      if (
        storageUser.idUtsAsignada !== storageUser.idUtsSeleccionada &&
        storageUser.isUtsAlternative === true
      ) {
        setAlternateApointment(false);
      } else {
        setAlternateApointment(true);
      }
      if (storageUser.idUts) {
        // console.log("Obteniendo DATOS POR DEFECTO");
        getUTS(storageUser.idUts);
        setDateInfo(
          storageCita.agenda_fecha + " a las " + storageCita.agenda_hora
        );
        setPartialCita({
          cita_id: storageCita.idAgenda,
          agenda_fecha: storageCita.agenda_fecha,
          agenda_hora: storageCita.agenda_hora,
        });
        setUserInfo(storageUser);
        setCitaInfo(storageCita);
        // console.log("Saliendo de obtener UTS", citaInfo);
      } else {
        // console.log("storageUser no tiene idUts", storageUser);
      }
    } else {
      // console.log("segunda condicion");
      storageUser.utsAssignedName = storageCita.uts_name;
      axios
        .get(
          process.env.REACT_APP_URL_API +
            "/Agenda/loadOnelibre/" +
            storageUser.idUts
        )
        .then((res) => {
          console.log("Cita seleccionada", res.data, citaInfo);
          let idUtsAsignada;
          let idUtsAlternativa = 0;
          if (storageUser.idUtsAsignadaHasToUpdate == true) {
            idUtsAsignada = storageUser.idUts;
            idUtsAlternativa = res.data.idUts;
            storageUser.idUtsAsignadaHasToUpdate = false;
          } else {
            idUtsAsignada = storageUser.idUtsAsignada;
            idUtsAlternativa = storageUser.idUtsAlternativa;
          }
          let idUtsSeleccionada = res.data.idUts;

          if (
            idUtsAlternativa !== idUtsAsignada &&
            res.data.isUtsAlternative === true
          ) {
            setAlternateApointment(false);
          } else {
            setAlternateApointment(true);
          }
          setAlternateIdUts(res.data.idUts);
          // setPartialCita({
          //   cita_id: res.data.idAgenda,
          //   agenda_fecha: res.data.fecha,
          //   agenda_hora: res.data.hora,
          // });
          storageUser.idUts = idUtsSeleccionada;
          storageUser.idUtsAsignada = idUtsAsignada;
          storageUser.idUtsAlternativa = idUtsAlternativa;
          storageUser.utsSelectedName = res.data.uts_name;
          storageCita.uts_id = idUtsSeleccionada;
          storageCita.idUtsAsignada = idUtsAsignada;
          storageCita.idUtsAlternativa = idUtsAlternativa;

          localStorage.removeItem("SSMURCIA-USER-INFO");
          localStorage.removeItem("SSMURCIA-CITA-INFO");
          setUserInfo([]);
          setCitaInfo([]);
          localStorage.setItem(
            "SSMURCIA-USER-INFO",
            JSON.stringify(storageUser)
          );
          localStorage.setItem(
            "SSMURCIA-CITA-INFO",
            JSON.stringify(storageCita)
          );

          storageUser = JSON.parse(localStorage.getItem("SSMURCIA-USER-INFO"));
          storageCita = JSON.parse(localStorage.getItem("SSMURCIA-CITA-INFO"));
          setUserInfo(storageUser);
          setCitaInfo(storageCita);
          console.log("storageUser", storageUser);
          console.log("storageCita", storageCita);

          if (storageCita.uts_id) {
            getUTS(storageCita.uts_id);
          }
          console.log("Cargando desde datos", storageCita);
          setPartialCita({
            cita_id: res.data.idAgenda,
            agenda_fecha: res.data.fecha,
            agenda_hora: res.data.hora,
          });
          setDateInfo(res.data.fecha + " a las " + res.data.hora);
        })
        .catch((err) => {
          setDateInfo(textNoCita);
        });
      console.log("QUE HEMOS HECHO", dateInfo);
    }
    if (dateInfo === textNoCita && dateInfo.length < 1) {
      Report.failure(
        "Agenda",
        "No hay citas abiertas para este dia",
        "Aceptar",
        () => (window.location.href = "/Inicio")
      );
      return false;
    } else {
      Report.info(
        "Cita",
        "¡No olvide confirmar la cita en su correspondiente botón!",
        "Cerrar"
      );
    }
  }, []);

  // useEffect(()=>{
  //   goToCoordinates(coordinates[0], coordinates[1])
  // }, [coordinates])
  const setCitaType = function (e) {
    // console.log("Radio button", e.target.value);
    let isP = true;
    if (e.target.value === "PRESENCIAL") {
      isP = true;
    } else {
      isP = false;
    }
    setIsPresencial(isP);
    citaInfo["isPresencial"] = isP;
    setCitaInfo(citaInfo);
    localStorage.setItem("SSMURCIA-CITA-INFO", JSON.stringify(citaInfo));
  };
  const addDayOfWeekName = function (dateText) {
    let dayOfWeekText = "";
    let formatedDateText = "";
    try {
      const weekday = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const monthNames = [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sept",
        "oct",
        "nov",
        "dic",
      ];
      let splitDate = dateText.split(" ");
      let myMonth = splitDate[0].slice(0, -1);
      let day = splitDate[1].slice(0, -1);
      let year = splitDate[2];
      // console.log("Y-M-D", year, myMonth, day);
      let monthNumber = monthNames.indexOf(myMonth, 0);
      // console.log("monthNumber", monthNumber);
      let myDay = new Date(year, monthNumber, day);
      // console.log(myDay, dateText);
      let dayOfWeek = myDay.getDay();
      dayOfWeekText = weekday[dayOfWeek];
      let divisionFecha = dateText.split(",");
      let divisionMes = divisionFecha[0].split(" ");
      let tmpDataText = divisionMes[1] + " " + divisionMes[0] + ".";
      let divisionHora = divisionFecha[1].split("las");
      // Tenemos a.m. o p.m. en el string?
      if (divisionHora[1].includes("a.", "p.")) {
        let posPoints = divisionHora[1].lastIndexOf(":");
        let sustituir = divisionHora[1].substring(
          posPoints,
          divisionHora[1].length
        );
        let tmpDataText2 = divisionHora[1].replace(sustituir, " Horas");

        divisionHora[1] = "las" + tmpDataText2;
      }
      formatedDateText = tmpDataText + divisionHora.join("");
    } catch {
      // console.log("Problema con el texto ", dateText);
      dayOfWeekText = "";
    }

    return dayOfWeekText + " " + formatedDateText;
  };

  return (
    <div className="pedirCita-form">
      <div className="pedirCita-row-1">
        <div className="pedirCita-icon">
          <BookmarkIcon
            sx={{
              fontSize: 35,
            }}
          />
        </div>
        <div className="pedirCita-row-1">
          <div className="pedirCita-text-1">Servicio de Cita Previa</div>
          <div className="pedirCita-text-2">Pedir Cita</div>
        </div>
      </div>
      <div className="pedirCita-row-2">
        <div className="pedirCita-row-2-header-box">
          <div>
            <i className="fa fa-info"></i>

            <label className="infoBef pedirCita-row-2-header-text">
              La primera cita disponible con su trabajador/a social
            </label>
          </div>
          <div class="col-md-6">
            <p>
              <i className="fa fa-calendar"></i>
              <b className="pedirCita-row-2-info">
                {dateInfo === textNoCita
                  ? textNoCita
                  : addDayOfWeekName(dateInfo)}
              </b>
            </p>
            <p>
              <i className="fa fa-hospital-o"></i>
              <b className="pedirCita-row-2-info">
                Unidad de Trabajo Social(UTS):
              </b>{" "}
              {citaInfo !== null ? citaInfo.uts_name : ""}
            </p>
            {/* <p hidden={citaInfo!=null && citaInfo.uts_telefono?"":true}>
          <i className="fa fa-phone"></i>
          <b className="pedirCita-row-2-info">Teléfono de la UTS: </b> {(citaInfo!=null )?citaInfo.uts_telefono:""}
        </p> */}
            {/* <p hidden={citaInfo !== null && citaInfo.uts_telefono2 ? "" : true}>
              <i className="fa fa-phone"></i>
              <b className="pedirCita-row-2-info">Teléfono de la UTS: </b>{" "}
              {citaInfo !== null ? citaInfo.uts_telefono2 : ""}
            </p> */}
            <p hidden={citaInfo !== null && citaInfo.uts_fax ? "" : true}>
              <i className="fa fa-phone"></i>
              <b className="pedirCita-row-2-info">FAX de la UTS: </b>{" "}
              {citaInfo !== null ? citaInfo.uts_fax : ""}
            </p>
            <p>
              <i className="fa fa-book"></i>
              <b className="pedirCita-row-2-info">Dirección de la UTS: </b>{" "}
              {citaInfo !== null ? citaInfo.uts_addr : ""}
            </p>

            <div
              className="pedirCita-row-2-info"
              id="alternateAppoinment"
              hidden
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "5px",
                display: alternateAppointment ? "none" : "block",
              }}
            >
              <p>
                Su UTS asignada ({storageUser.utsAssignedName}) se encuentra
                cerrada durante el mes de agosto.
              </p>
              <p>Su nueva uts es {citaInfo.uts_name} </p>
            </div>

            <div
              hidden={dateInfo === textNoCita ? "true" : ""}
              onChange={setCitaType.bind(this)}
              className="pedirCita-radioButtons"
            >
              <label style={{ marginTop: "1.5rem" }}>
                <strong>Cita telefónica</strong>
              </label>
              <div>
                <input
                  type="radio"
                  value="TELEFONO"
                  name="Cita telefónica"
                  checked={!isPresencial}
                />{" "}
                Seleccione esta opción para que el trabajador/a social le llame
                por teléfono. Usted no tiene que desplazarse a la UTS.
              </div>
              <label style={{ marginTop: "1.5rem" }}>
                <strong>Cita presencial</strong>
              </label>
              <div>
                <input
                  type="radio"
                  value="PRESENCIAL"
                  name="Cita presencial"
                  checked={isPresencial}
                />{" "}
                Seleccione esta opción si necesita que le atiendan de forma
                presencial en la UTS. Usted tiene que desplazarse a la UTS.
              </div>
            </div>
          </div>
        </div>

        {/* <div class = 'col-md-6 col6-box'> */}
        <div style={{ width: "100%" }}>
          <p style={{ paddingLeft: "3rem" }}>
            <i className="fa fa-map-marker"></i>
            <b className="pedirCita-col-2-info">Dirección en el mapa:</b>
          </p>
          {coordinates.length > 0 &&
          coordinates[0] !== "" &&
          textoMarker !== "UTS" ? (
            <div className="map-box">
              <MapObject
                coordinates={coordinates}
                zoom={17}
                textoMarker={textoMarker}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="pedirCita-row-3">
        <div className="pedirCita-button-1">
          <Button
            variant="outlined"
            startIcon={<CheckIcon />}
            sx={{
              color: "#333",
              backgroundColor: "#f4f4f4",
              borderColor: "#ddd",
              padding: "0.9rem 6rem",
              width: "100%",
              "&:hover": {
                backgroundColor: "#e7e7e7",
                borderColor: "#adadad;",
                boxShadow: "none",
              },
            }}
            disabled={dateInfo === textNoCita ? true : ""}
            onClick={(e) => {
              e.preventDefault();
              setOpenConfirm(true);
            }}
          >
            Confirmar Cita
          </Button>
          <ConfirmDialog
            open={openConfirm}
            setOpen={setOpenConfirm}
            onClose={() => {
              setOpenConfirm(false);
            }}
            onConfirm={() => reservar()}
          >
            ¿Confirma la reserva?
          </ConfirmDialog>
        </div>

        <div className="pedirCita-button-1">
          <Button
            variant="outlined"
            startIcon={<CalendarMonthIcon />}
            disabled={dateInfo === textNoCita ? "true" : ""}
            sx={{
              color: "#333",
              backgroundColor: "#f4f4f4",
              borderColor: "#ddd",
              padding: "0.9rem 6rem",
              width: "100%",

              "&:hover": {
                backgroundColor: "#e7e7e7",
                borderColor: "#adadad;",
                boxShadow: "none",
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/CambiarCita";
            }}
          >
            Cambiar Cita
          </Button>
        </div>
        <div className="pedirCita-button-1">
          <Button
            variant="outlined"
            startIcon={<CloseIcon />}
            sx={{
              color: "#333",
              backgroundColor: "#f4f4f4",
              borderColor: "#ddd",
              padding: "0.9rem 6rem",
              width: "100%",

              "&:hover": {
                backgroundColor: "#e7e7e7",
                borderColor: "#adadad;",
                boxShadow: "none",
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              // axios.post(process.env.REACT_APP_URL_API+'/Agenda/cancelar', {
              //   idAgenda:partialCita.cita_id,
              //   idUsuario:userInfo.idUsuarioWeb
              // }).then((res)=> {
              //   console.log("Exito cancelando", partialCita.cita_id, res.data)
              //   setCitaInfo({
              //   ...citaInfo,
              //   cita_id:partialCita.cita_id,
              //   agenda_fecha:partialCita.agenda_fecha,
              //   agenda_hora:partialCita.agenda_hora,
              //   });
              //   localStorage.setItem("SSMURCIA-CITA-INFO", JSON.stringify({...citaInfo,
              //     cita_id:partialCita.cita_id,
              //     agenda_fecha:partialCita.agenda_fecha,
              //     agenda_hora:partialCita.agenda_hora}));
              //   setTimeout(function() {
              //     console.log("Cancel success")
              //     window.location.href = "/Inicio";
              //   }, 200);

              // }).catch(err=>{
              //   console.log("Problema cancelando", err)

              // })
              window.location.href = "/Inicio";
            }}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </div>
  );
};
